/* eslint-disable no-unused-vars */
import useFormState from "../state";
import store from "../../../store";
import { countryToCountryCode } from "../../../utils";
import { QuoteTargetEnum } from "@gid/models";

export default function useFormCustomer(props) {
  const formState = useFormState(props);

  function processCustomerData() {
    const apiMapping = {
      salutation: { field: "salutation" },
      first_name: { field: "first_name" },
      last_name: { field: "last_name" },
      company_name: { field: "company_name" },
      email: {
        field: "email",
        transformValue: (value) => (!value ? null : value),
      },
      phone: { field: "phone" },
      street: {
        field: "street",
        belongsTo: "shipping_address",
      },
      postal_code: {
        field: "postal_code",
        belongsTo: "shipping_address",
      },
      city: { field: "city", belongsTo: "shipping_address" },
      country: {
        field: "country_code",
        belongsTo: "shipping_address",
        transformValue: countryToCountryCode,
      },
      billing_address_email: {
        field: "email_for_invoices",
      },
      billing_address_first_name: {
        field: "billing_first_name",
      },
      billing_address_last_name: {
        field: "billing_last_name",
      },
      billing_address_company_name: {
        field: "billing_company_name",
      },
      billing_address_street: {
        field: "street",
        belongsTo: "billing_address",
      },
      billing_address_postal_code: {
        field: "postal_code",
        belongsTo: "billing_address",
      },
      billing_address_city: {
        field: "city",
        belongsTo: "billing_address",
      },
      billing_address_country: {
        field: "country_code",
        belongsTo: "billing_address",
        transformValue: countryToCountryCode,
      },
    };

    const customerFlowItems = store.getters.allWizardItems.filter((x) =>
      Object.keys(apiMapping).includes(x.name)
    );

    const data = {
      salutation: null,
      first_name: null,
      last_name: null,
      company_name: "",
      email: null,
      phone: null,
      mobile_phone: null,
      shipping_address: {
        city: null,
        country_code: null,
        postal_code: null,
        street: null,
      },
      billing_address: {
        city: null,
        country_code: null,
        postal_code: null,
        street: null,
      },
      billing_first_name: null,
      billing_last_name: null,
      billing_company_name: null,
      email_for_invoices: null,
      eid: store.getters.customerEid || null,
    };

    customerFlowItems.forEach((wizardItem) => {
      const mappedField = apiMapping[wizardItem.name];

      let value = trim(wizardItem.answer);

      if (Object.keys(mappedField).includes("transformValue")) {
        value = mappedField.transformValue(value);
      }

      if (Object.keys(mappedField).includes("belongsTo")) {
        data[mappedField.belongsTo][mappedField.field] = value;
      } else {
        data[mappedField.field] = value;
      }
    });

    if (!data.billing_first_name) {
      delete data.billing_first_name;
    }
    if (!data.billing_last_name) {
      delete data.billing_last_name;
    }
    if (!data.billing_company_name) {
      delete data.billing_company_name;
    }
    if (!data.email_for_invoices) {
      delete data.email_for_invoices;
    }
    if (Object.values(data.billing_address).some((x) => x === null)) {
      delete data.billing_address;
    }

    formState.orderIntake.value.order.customer = data;
  }

  function processContactPersonData() {
    const apiMapping = {
      contact_person_first_name: { field: "first_name" },
      contact_person_last_name: { field: "last_name" },
      contact_person_email: {
        field: "email",
        transformValue: (value) => (!value ? null : value),
      },
      contact_person_phone: { field: "phone" },
      contact_person_use_for_email: {
        field: "use_for_email",
        transformValue: (value) =>
          value == null || value === "" ? value : value === "true",
      },
      contact_person_quote_target: {
        field: "quote_target",
        transformValue: (value) => {
          switch (value?.toLowerCase()) {
            case "die beiden":
              return QuoteTargetEnum.ALL;
            case "nur der ansprechpartner":
              return QuoteTargetEnum.CONTACT_PERSON;
            case "hauptkunde":
              return QuoteTargetEnum.CUSTOMER;
            default:
              return value;
          }
        },
      },
    };

    const contactPersonFlowItems = store.getters.allWizardItems.filter((x) =>
      Object.keys(apiMapping).includes(x.name)
    );

    const data = {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      use_for_email: null,
      quote_target: null,
    };

    contactPersonFlowItems.forEach((wizardItem) => {
      const mappedField = apiMapping[wizardItem.name];

      let value = trim(wizardItem.answer);

      if (Object.keys(mappedField).includes("transformValue")) {
        value = mappedField.transformValue(value);
      }

      if (Object.keys(mappedField).includes("belongsTo")) {
        data[mappedField.belongsTo][mappedField.field] = value;
      } else {
        data[mappedField.field] = value;
      }
    });

    const useContactPerson = !Object.values(data).every((v) => v === null);

    if (!data.use_for_email && useContactPerson) {
      data.use_for_email = false;
    }

    formState.orderIntake.value.order.contact_person = data;
    formState.orderIntake.value.order.use_contact_person = useContactPerson;
  }

  function trim(value) {
    if (typeof value === "string" || value instanceof String) {
      return value.trim();
    }
    return value;
  }

  return {
    processCustomerData,
    processContactPersonData,
  };
}
