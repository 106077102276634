/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";

export default function useFormProject(props) {
  const formState = useFormState(props);

  function processProject() {
    const projectSequenceSet = new Set([
      ...formState.orderIntake.value.order.services.map(
        (x) => x.project_sequence
      ),
      ...formState.orderIntake.value.order.products.map(
        (x) => x.project_sequence
      ),
    ]);
    if (projectSequenceSet.size <= 1) {
      formState.orderIntake.value.order.project_id =
        store.getters.projectId || generateProjectId();
    }
    if (projectSequenceSet.size > 1) {
      formState.orderIntake.value.order.project_id =
        store.getters.projectId || generateProjectId();
      formState.orderIntake.value.order.project_manager_sfid = null;
    }
  }

  function generateProjectId() {
    return `order-intake-forms|${props.form.id}|${props.form.opportunity.sfid}|${formState.orderIntake.value.hash}`;
  }

  return {
    processProject,
  };
}
