/* eslint-disable no-unused-vars */
import { ANSWER_SET, WIZARD_ITEM_VISIBLE_SET } from "@/store/form.module/types";

export default {
  methods: {
    findWizardItemById(id) {
      return this.$store.getters.wizardItemById(id);
    },
    doesItemDependsOnSomething() {
      const result = this.$store.getters.wizardItemDependsOnSomething(
        this.item.id
      );
      this.setDefaultAnswer(result);
      this.setItemVisible(result);
      return result;
    },
    cleanDependentItemsOf(currentItem) {
      const items = this.$store.getters
        .getDependentItemsOf(currentItem)
        .filter((x) => x.id !== currentItem.id);
      items.forEach((x) => {
        if (x.type !== "hidden") {
          this.$store.dispatch(ANSWER_SET, {
            answer: null,
            id: x.id,
            errors: [],
          });
        }
      });
    },
    runTriggers() {
      if (this.item.triggers) {
        this.item.triggers.forEach((trigger) => {
          const triggerActions = { setAnswer: [] };

          if (trigger.items) {
            for (let [itemId, itemsList] of Object.entries(trigger.items)) {
              let itemToEvaluate = this.findWizardItemById(itemId);

              if (Array.isArray(itemsList)) {
                itemsList.forEach((itemObj) => {
                  if (
                    itemToEvaluate !== undefined &&
                    Object.keys(itemObj).includes("conditions")
                  ) {
                    if (
                      itemObj.conditions.some(
                        (x) => x.answer == itemToEvaluate.answer
                      )
                    ) {
                      if (itemObj.actions && itemObj.actions.setAnswer) {
                        if (itemObj.actions.setAnswer.items) {
                          for (let [
                            actionOnItemId,
                            actionOnItemObj,
                          ] of Object.entries(
                            itemObj.actions.setAnswer.items
                          )) {
                            triggerActions.setAnswer.push({
                              answer: actionOnItemObj.answer,
                              id: actionOnItemId,
                              errors: [],
                            });
                          }
                        }
                      }
                    }
                  }
                });
              }
            }
          }

          triggerActions.setAnswer.forEach((x) => {
            this.$store.dispatch(ANSWER_SET, {
              answer: x.answer,
              id: x.id,
              errors: x.errors,
            });
          });
        });
      }
    },
    setDefaultAnswer(visible) {
      if (
        visible &&
        this.answerWizardItem === null &&
        this.item &&
        this.item.defaultAnswer
      ) {
        this.$store.dispatch(ANSWER_SET, {
          answer: this.item.defaultAnswer,
          id: this.item.id,
          errors: [],
        });
      }
    },
    setItemVisible(value) {
      this.$store.dispatch(WIZARD_ITEM_VISIBLE_SET, {
        visible: value,
        id: this.item.id,
      });
    },
    async blurAction(value) {
      // eslint-disable-line
      if (this.processPricing) {
        this.processPricing();
      }
      this.runTriggers();
    },
    async changeAction(value) {
      // eslint-disable-line
      if (this.processPricing) {
        this.processPricing();
      }
      this.runTriggers();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  created() {
    this.runTriggers();
  },
};
