<template>
  <div v-show="isVisible">
    <validation-provider
      ref="vp"
      :name="itemTitle"
      :rules="rules"
      v-slot="validationContext"
    >
      <b-form-group label-size="lg">
        <template v-slot:label>
          <span v-html="itemTitle"></span
          ><sup v-if="item.required" class="gid-asterisk">*</sup>
        </template>
        <p v-if="item.errors.length" class="lead">
          <span v-for="error in item.errors" :key="error" class="gid-error"
            >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
          >
        </p>
        <p
          v-if="item.explanation"
          v-html="item.explanation"
          class="lead__explanation"
        ></p>
        <b-form-input
          v-model="answerWizardItem"
          :type="item.type"
          :name="item.name"
          :state="getValidationState(validationContext)"
          pattern="/^-?\d+\.?\d*$/"
          @keypress="inputTypeNumberOnKeyPress($event, item)"
          :min="
            item.metadata && item.metadata.min !== undefined
              ? item.metadata.min
              : null
          "
          :max="
            item.metadata && item.metadata.max !== undefined
              ? item.metadata.max
              : null
          "
          @change="changeAction"
        >
          <span class="lead float-right" v-if="item.suffix">{{
            item.suffix
          }}</span>
        </b-form-input>

        <b-form-invalid-feedback :id="String(item.id)">{{
          validationContext.errors[0]
        }}</b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";

export default {
  name: "WizardItemNumber",
  mixins: [WizardItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem.trim(),
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
    rules() {
      const rules = {
        required: this.item.required,
      };

      if (
        this.item.metadata &&
        this.item.metadata.min !== undefined &&
        this.item.metadata.min !== null
      ) {
        rules.min_value = Number(this.item.metadata.min);
      }
      if (
        this.item.metadata &&
        this.item.metadata.max !== undefined &&
        this.item.metadata.max !== null
      ) {
        rules.max_value = Number(this.item.metadata.max);
      }

      return rules;
    },
    validationProvider() {
      return this.$refs[`vp-${this.item.id}`];
    },
  },
  methods: {
    inputTypeNumberOnKeyPress(event, item) {
      if (item.metadata && item.metadata.maxlength) {
        if (event.target.value.length >= item.metadata.maxlength) {
          event.preventDefault();
        }
      }
    },
  },
  created() {},
  mounted() {
    this.$watch(
      () => {
        return this.$refs.vp.errors;
      },
      (val) => {
        this.item.feErrors = val || [];
      }
    );
  },
};
</script>
