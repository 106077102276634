<template>
  <div v-show="isVisible">
    <b-form-group label-size="lg" class="input-group">
      <template v-slot:label>
        <span v-html="itemTitle"></span
        ><sup v-if="item.required" class="gid-asterisk">*</sup>
      </template>
      <p v-if="item.errors.length" class="lead">
        <span v-for="error in item.errors" :key="error" class="gid-error"
          >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
        >
      </p>
      <p
        v-if="item.explanation"
        v-html="item.explanation"
        class="lead__explanation"
      ></p>
      <b-form-row>
        <b-col>
          <b-form-group
            :label="
              timeInterval.external.labels[
                this.$store.getters.formSettings.locale
              ].start
            "
            label-size="lg"
          >
            <b-form-timepicker
              v-model="timeInterval.start"
              v-bind="
                timeInterval.internal.labels[
                  this.$store.getters.formSettings.locale
                ] || {}
              "
              :locale="this.$store.getters.formSettings.locale"
              @input="onTimeIntervalSet"
              @context="onTimeIntervalContext"
            ></b-form-timepicker>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="
              timeInterval.external.labels[
                this.$store.getters.formSettings.locale
              ].end
            "
            label-size="lg"
          >
            <b-form-timepicker
              v-model="timeInterval.end"
              v-bind="
                timeInterval.internal.labels[
                  this.$store.getters.formSettings.locale
                ] || {}
              "
              :locale="this.$store.getters.formSettings.locale"
              @input="onTimeIntervalSet"
              @context="onTimeIntervalContext"
            ></b-form-timepicker>
          </b-form-group>
        </b-col>

        <b-form-input
          v-show="false"
          v-model="answerWizardItem"
          type="text"
        ></b-form-input>
      </b-form-row>
    </b-form-group>
  </div>
</template>

<script>
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";

export default {
  name: "WizardItemTimeInterval",
  mixins: [WizardItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeInterval: {
        start: "08:00",
        end: "12:00",
        context: {},
        external: {
          labels: {
            de: {
              start: "Startzeit",
              end: "Endzeit",
            },
            fr: {
              start: "Heure de début",
              end: "Heure de fin",
            },
          },
        },
        internal: {
          labels: {
            de: {
              labelHours: "Stunden",
              labelMinutes: "Minuten",
              labelSeconds: "Sekunden",
              labelIncrement: "Erhöhen",
              labelDecrement: "Verringern",
              labelSelected: "Ausgewählte Zeit",
              labelNoTimeSelected: "Keine Zeit ausgewählt",
              labelCloseButton: "Schließen",
            },
            fr: {
              labelHours: "Heures",
              labelMinutes: "Minutes",
              labelSeconds: "Secondes",
              labelIncrement: "Augmenter",
              labelDecrement: "Diminuer",
              labelSelected: "Heure sélectionnée",
              labelNoTimeSelected: "Aucune heure sélectionnée",
              labelCloseButton: "Fermer",
            },
          },
        },
      },
    };
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem,
          id: this.item.id,
          errors: [],
        });
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
  },
  methods: {
    onTimeIntervalContext(ctx) {
      this.timeInterval.context = ctx;
    },
    onTimeIntervalSet() {
      if (this.timeInterval.start && this.timeInterval.end) {
        this.$store.dispatch(ANSWER_SET, {
          answer: `${this.timeInterval.start} - ${this.timeInterval.end}`,
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
      }
    },
  },
  created() {
    this.onTimeIntervalSet();
  },
};
</script>
