<template>
  <div v-show="isVisible">
    <b-form-group
      :class="`radio ${item.name}`"
      label-size="lg"
      v-if="!reservedNames.includes(item.name)"
    >
      <template v-slot:label>
        <span v-html="itemTitle"></span
        ><sup v-if="item.required" class="gid-asterisk">*</sup>
      </template>
      <p
        v-if="item.explanation"
        v-html="item.explanation"
        class="lead__explanation"
      ></p>
      <b-form-radio
        v-for="opt in item.options"
        :key="opt"
        :name="item.name"
        :value="opt"
        v-model="answerWizardItem"
        @change="changeAction"
        class="lead"
        >{{ opt }}</b-form-radio
      >
    </b-form-group>
    <p v-else class="lead">
      <span v-for="opt in item.options" :key="opt">{{ opt }}</span>
    </p>
  </div>
</template>

<script>
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";

const reservedNames = ["billing_address_country", "country"];

export default {
  name: "WizardItemRadio",
  mixins: [WizardItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      reservedNames,
    };
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem,
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
        this.cleanDependentItemsOf(this.item);
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
  },
  methods: {},
  created() {
    if (reservedNames.includes(this.item.name)) {
      this.$store.dispatch(ANSWER_SET, {
        answer: this.item.options[0],
        id: this.item.id,
        errors: [],
      });
    }
  },
};
</script>
