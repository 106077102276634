<template>
  <div class="text-center mb-4 pb-4">
    <h1 class="mt-4 mb-4">{{ title }}</h1>
    <b-alert variant="success" show>
      <div class="lead" v-html="message"></div>
    </b-alert>

    <template v-if="hasAdditionalInfo">
      <b-alert v-if="hasCustomerSubsidy" variant="success" show>
        <h4 class="alert-heading" v-html="customerSubsidy.title"></h4>
        <p v-html="customerSubsidy.description"></p>
        <hr />
        <p class="mb-0">
          <a
            class="btn btn-primary"
            target="_blank"
            role="button"
            :href="customerSubsidy.buttons.apply.link"
            v-html="customerSubsidy.buttons.apply.name"
          ></a>
        </p>
      </b-alert>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { computed, inject } from "@vue/composition-api";
import i18n from "../i18n";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: false,
    },
    order: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const store = inject("vuex-store");

    // computed properties
    const hasAdditionalInfo = computed(() => {
      return i18n.te("success.additionalInfo");
    });

    const additionalInfo = computed(() => {
      if (hasAdditionalInfo.value) {
        return i18n.t("success.additionalInfo");
      } else {
        return null;
      }
    });

    const hasCustomerSubsidy = computed(() => {
      const blackListProductIds = [314, 1457, 1456, 717, 716];
      return (
        store.getters.opportunity.options.subsidy &&
        props.order.is_installation &&
        props.order.products.some(({ id }) =>
          blackListProductIds.includes(id)
        ) &&
        i18n.te("success.additionalInfo.customer-subsidy")
      );
    });

    const customerSubsidy = computed(() => {
      if (hasCustomerSubsidy.value) {
        return i18n.t("success.additionalInfo.customer-subsidy");
      } else {
        return null;
      }
    });

    // methods
    function finish() {
      context.emit("finish", true);
    }

    return {
      // ...formState,
      // computed properties
      hasAdditionalInfo,
      additionalInfo,
      hasCustomerSubsidy,
      customerSubsidy,
      // methods
      finish,
    };
  },
};
</script>
