/* eslint-disable no-unused-vars */
import { computed, reactive } from "@vue/composition-api";
import { toRefs } from "@vue/composition-api";
import useFormState from "./state";

export default function useFormOrder(props) {
  const formState = useFormState(props);

  function clearOrderIntakeFormData() {
    delete formState.orderIntake.value.formData;
    formState.orderIntake.value.formData = new FormData();
  }

  return {
    clearOrderIntakeFormData,
  };
}
