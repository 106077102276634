<template>
  <div id="app">
    <identification
      v-if="!opportunityId"
      :formRef="form"
      :locale="locale"
      :assistantId="assistantId"
      @proofIdentity="proofIdentity"
    >
    </identification>
    <GID
      v-else
      :formRef="form"
      :opportunityId="opportunityId"
      :locale="locale"
      :orderId="orderId"
      :accessToken="accessToken"
      :affiliateId="affiliateId"
      :eid="eid"
      :projectId="projectId"
      :brand2="brand2"
      :ownInstaller="ownInstaller"
      :externalInvoicingId="externalInvoicingId"
      :routeParams="params"
    >
    </GID>

    <!-- Chat Integration (Persistent Chat Component) -->
    <div v-if="assistantId" class="chat-section">
      <b-button v-b-modal.chat-modal class="chat-icon-button"> 💬 </b-button>
      <p class="chat-text" v-b-modal.chat-modal>
        Fragen Sie den GID Assistenten!
      </p>
      <b-modal
        id="chat-modal"
        :title="chatTitle"
        hide-footer
        centered
        size="lg"
        body-class="modal-body-custom"
        header-class="modal-header-custom"
        content-class="modal-content-custom"
      >
        <ChatView
          :assistantId="assistantId"
          :messages="chatMessages"
          @updateMessages="updateChatMessages"
          class="chat-view"
        ></ChatView>
      </b-modal>
    </div>

    <!-- force preloading of material symbols -->
    <div
      aria-hidden="true"
      style="
        visibility: hidden;
        position: absolute;
        overflow: hidden;
        height: 0;
      "
    >
      <span class="material-symbols-outlined">face</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import GID from "@/views/GID.vue";
import Identification from "@/components/Identification.vue";
import ChatView from "@gid/vue-common/components/chat-view/chat.vue";

export default {
  name: "App",
  components: {
    GID,
    Identification,
    ChatView,
  },
  data() {
    return {
      form: null,
      opportunityId: null,
      publishResizeEvents: false,
      resize: "false",
      height: "auto",
      scrollY: "auto",
      locale: "de",
      theme: "default",
      order: null,
      params: [],
      accessToken: null,
      affiliateId: null,
      externalInvoicingId: null,
      assistantId: null, // Add assistantId to data
      chatTitle: "GID Assistant", // Default chat title
      chatMessages: [], // Chat messages to maintain state
    };
  },
  methods: {
    collectURLParams() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);

      this.form = params.get("form");
      this.opportunityId = params.get("opportunity");
      this.resize = params.get("resize");
      this.publishResizeEvents = params.get("resize");
      this.locale = params.get("locale") || this.locale;
      this.theme = params.get("theme") || this.theme;
      this.orderId = params.get("order");
      this.accessToken = params.get("access-token");
      this.affiliateId =
        params.get("affiliate-id") || params.get("affiliate_id");
      this.externalInvoicingId = params.get("sales-partner");
      this.eid = params.get("eid");
      this.projectId = params.get("project_id") || params.get("project-id");
      this.brand2 = params.get("brand2");
      this.ownInstaller = params.get("own_installer");
      this.assistantId = params.get("assistantId"); // Add assistantId from URL
      this.chatTitle = params.get("chatTitle") || this.chatTitle; // Add chatTitle from URL
      params.forEach((value, key) => {
        this.params = this.params.concat({ [key]: value });
      });
    },
    async proofIdentity({ valid, result }) {
      if (valid) {
        Object.keys(result).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(this, key)) {
            this[key] = result[key];
          }
        });
      }
    },
    updateChatMessages(messages) {
      this.chatMessages = messages;
    },
  },
  mounted() {
    if (this.publishResizeEvents) {
      let recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        "js/iframeResizer.contentWindow.min.js"
      );
      document.head.appendChild(recaptchaScript);
    }
  },
  created() {
    this.collectURLParams();
    this.$i18n.locale = this.locale;
    this.$root.$i18n.locale = this.locale;

    const html = document.documentElement;
    html.setAttribute("lang", this.locale);

    import(`@/assets/scss/themes/${this.theme}.scss`);

    axios.interceptors.response.use(
      function (response) {
        const probeRegex = /(api)\/([a-zA-Z0-9_-]+)\/(probe)\/([a-zA-Z0-9]*)/;
        if (response.config.url.match(probeRegex)) {
          if (!response.data.finished) {
            throw new axios.Cancel({ jobApiId: response.data.jobApiId });
          } else {
            return response;
          }
        }
        return response;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";
</style>
