var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-jumbotron',{attrs:{"header-level":"6"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.title))]},proxy:true},{key:"lead",fn:function(){return [_vm._v(_vm._s(_vm.current.headline))]},proxy:true}])},[(_vm.current.description)?_c('h6',{domProps:{"innerHTML":_vm._s(_vm.current.description)}}):_vm._e(),_c('hr',{staticClass:"my-4"}),_vm._t("price"),(_vm.wizardItems)?_vm._l((_vm.wizardItems),function(item){return _c('wizard-item',{key:item.id,attrs:{"id":item.id,"processPricing":_vm.processPricing}})}):_vm._e(),(_vm.emptyRequiredWizardItems && _vm.emptyRequiredWizardItems.length)?_c('b-card',{staticClass:"my-5",attrs:{"no-body":"","header":_vm.$t('to-continue')}},[_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.emptyRequiredWizardItems),function(item){return _c('b-list-group-item',{key:item.id,staticClass:"text-danger",domProps:{"innerHTML":_vm._s(item.title)}})}),1)],1):_vm._e(),(_vm.formHasErrors)?_c('div',_vm._l((_vm.formErrors),function(error,index){return _c('span',{key:index,staticClass:"gid-error",domProps:{"innerHTML":_vm._s(error)}})}),0):_vm._e(),_c('div',[_c('div',{staticClass:"text-center buttons-container"},[_c('p',{staticClass:"lead text-muted"},[_c('span',{staticClass:"gid-asterisk"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("mandatory-field"))+" ")]),_c('b-button-group',[(!_vm.$store.getters.isFirstFlow(_vm.flowStep))?_c('b-button',{staticClass:"previous",attrs:{"disabled":_vm.$store.getters.hasLoadingFileTypeItems,"variant":"outline-secondary"},on:{"click":function($event){return _vm.previousFlowStep()}}},[(_vm.buttons.previous.loading)?_c('b-spinner',{staticClass:"gid-spinner--button mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("form.buttons.previous.name"))+" ")],1):_vm._e(),(
            !_vm.$store.getters.isLastFlow(_vm.flowStep) && !_vm.$store.getters.order.id
          )?_c('b-button',{staticClass:"next",attrs:{"disabled":!_vm.$store.getters.hasAllAnsweredFlowItems(_vm.current) ||
            _vm.$store.getters.hasLoadingFileTypeItems,"variant":"secondary"},on:{"click":function($event){return _vm.nextFlowStep()}}},[(_vm.buttons.next.loading)?_c('b-spinner',{staticClass:"gid-spinner--button mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("form.buttons.next.name"))+" ")],1):(
            !_vm.$store.getters.isLastFlow(_vm.flowStep) && _vm.$store.getters.order.id
          )?_c('b-button',{staticClass:"pre-save",attrs:{"disabled":_vm.buttons.preSave.loading ||
            !_vm.$store.getters.hasAllAnsweredFlowItems(_vm.current) ||
            _vm.$store.getters.hasLoadingFileTypeItems,"variant":"secondary"},on:{"click":function($event){return _vm.preSaveFlowStep({ direction: 'next' })}}},[(_vm.buttons.preSave.loading)?_c('b-spinner',{staticClass:"gid-spinner--button mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("form.buttons.pre-save-and-next.name"))+" ")],1):_vm._e(),(
            _vm.$store.getters.isLastFlow(_vm.flowStep) &&
            _vm.hasSummary &&
            !_vm.$store.getters.order.id
          )?_c('b-button',{staticClass:"mr-2 summary",attrs:{"disabled":!_vm.$store.getters.hasAllAnsweredFlowItems(_vm.current) ||
            _vm.$store.getters.hasLoadingFileTypeItems,"variant":"primary"},on:{"click":function($event){return _vm.toSummary()}}},[(_vm.buttons.summary.loading)?_c('b-spinner',{staticClass:"gid-spinner--button mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("form.buttons.summary.name"))+" ")],1):(
            _vm.$store.getters.isLastFlow(_vm.flowStep) && _vm.$store.getters.order.id
          )?_c('b-button',{staticClass:"pre-save",attrs:{"disabled":_vm.buttons.preSave.loading ||
            !_vm.$store.getters.hasAllAnsweredFlowItems(_vm.current) ||
            _vm.$store.getters.hasLoadingFileTypeItems,"variant":"secondary"},on:{"click":function($event){return _vm.preSaveFlowStep({ direction: 'summary' })}}},[(_vm.buttons.preSave.loading)?_c('b-spinner',{staticClass:"gid-spinner--button mr-2"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t("form.buttons.summary.name"))+" ")],1):_vm._e(),_vm._t("buttons")],2)],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }