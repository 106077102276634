/* eslint-disable no-unused-vars */
import { reactive, ref } from "@vue/composition-api";
import { toRefs } from "@vue/composition-api";

let buttons = {
  submit: {
    loading: false,
    label: null,
  },
  preSave: {
    loading: false,
  },
  previous: {
    loading: false,
  },
  next: {
    loading: false,
  },
  summary: {
    loading: false,
  },
};

let orderIntake = {
  order: null,
  price: null,
  hash: null,
  priceMin: null,
  priceMax: null,
  formData: new FormData(),
};

let formState = reactive({
  flowStep: 0,
  buttons,
  summary: false,
  orderIntake,
});

export default function useFormState(props) {
  return { ...toRefs(formState) };
}
