/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueI18n from "vue-i18n";
import moment from "../moment";
import messages from "./messages";
import locales from "./locales";

Vue.use(VueI18n);
const defaultLocale = "de";

function getMessages(locale) {
  return { ...(messages[locale] || {}) };
}

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "de",
  silentFallbackWarn: true,
  messages: locales.reduce((x, key) => {
    x[key] = getMessages(key);
    return x;
  }, {}),
});

Vue.use(moment, i18n.locale);

export default i18n;
