<template>
  <div v-show="isVisible">
    <validation-provider
      ref="vp"
      :name="itemTitle"
      :rules="rules"
      v-slot="validationContext"
    >
      <b-form-group>
        <p v-if="item.errors.length">
          <span v-for="error in item.errors" :key="error" class="gid-error"
            >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
          >
        </p>
        <b-form-checkbox
          v-model="answerWizardItem"
          :name="item.name"
          :state="getValidationState(validationContext)"
          :value="checkedValue"
          :unchecked-value="uncheckedValue"
          @change="changeAction"
          class="lead"
        >
          <span v-html="itemTitle"></span
          ><sup v-if="item.required" class="gid-asterisk">*</sup>
        </b-form-checkbox>
      </b-form-group>
      <p v-if="item.explanation" v-html="item.explanation"></p>
    </validation-provider>
  </div>
</template>

<script>
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";

export default {
  name: "WizardItemCheckbox",
  mixins: [WizardItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem,
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
        this.cleanDependentItemsOf(this.item);
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
    checkedValue() {
      let value = "true";
      if (Array.isArray(this.item.options)) {
        const checkedValues = ["ja", "ein", "eine", "yes", "one", "true"];
        for (let index = 0; index < checkedValues.length; index++) {
          const result = this.item.options.find(
            (x) => x.toLowerCase() === checkedValues[index]
          );
          if (result) {
            value = result;
            break;
          }
        }
      }
      return value;
    },
    uncheckedValue() {
      let value = "false";
      if (Array.isArray(this.item.options)) {
        const checkedValues = ["nein", "kein", "keine", "no", "none", "false"];
        for (let index = 0; index < checkedValues.length; index++) {
          const result = this.item.options.find(
            (x) => x.toLowerCase() === checkedValues[index]
          );
          if (result) {
            value = result;
            break;
          }
        }
      }
      return value;
    },
    hasValidateOnlyAnswerRule() {
      return (
        this.item.metadata &&
        Object.keys(this.item.metadata).includes("validate-only-answer")
      );
    },
    rules() {
      const initialRules = {
        required: this.item.required,
      };
      const additionalRules = {};
      if (this.hasValidateOnlyAnswerRule) {
        additionalRules["is"] = String(
          this.item.metadata["validate-only-answer"]
        );
      }
      return { ...initialRules, ...additionalRules };
    },
    validationProvider() {
      return this.$refs[`vp-${this.item.id}`];
    },
  },
  methods: {},
  mounted() {
    this.$refs.vp.validate();
    this.$watch(
      () => {
        return this.$refs.vp.errors;
      },
      (val) => {
        this.item.feErrors = val || [];
      }
    );
  },
};
</script>
