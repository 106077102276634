import moment from "moment";

// moment.locale('de');

export default function install(Vue, locale) {
  moment.locale(locale);
  Object.defineProperties(Vue.prototype, {
    $moment: {
      get() {
        return moment;
      },
    },
  });
}
