export const AFFILIATE_ID_SET = "AFFILIATE_ID_SET",
  ANSWER_APPEND = "ANSWER_APPEND",
  ANSWER_SET = "ANSWER_SET",
  AUTH_SET = "AUTH_SET",
  BRAND2_SET = "brand2",
  CUSTOMER_EMAIL_FOR_INVOICES_SET = "customerEmailForInvoices",
  CUSTOMER_EMAIL_FOR_ORDERS_SET = "customerEmailForOrders",
  CUSTOMER_FIRSTNAME_SET = "customerFirstname",
  CUSTOMER_LASTNAME_SET = "customerLastname",
  CUSTOMER_MOBILE_PHONE_SET = "customerMobilePhone",
  CUSTOMER_PHONE_SET = "customerPhone",
  CUSTOMER_SALUTATION_SET = "customerSalutation",
  CUSTOMER_SET = "CUSTOMER_SET",
  CUSTOMER_SIGNATURE_SET = "customerSignature",
  CUSTOMER_SHIPPING_CITY_SET = "customerShippingCity",
  CUSTOMER_SHIPPING_COUNTRY_CODE_SET = "customerShippingCountryCode",
  CUSTOMER_SHIPPING_POSTALCODE_SET = "customerShippingPostalCode",
  CUSTOMER_SHIPPING_STREET_SET = "customerShippingStreet",
  EID_SET = "eid",
  EXTERNAL_INVOICING_ID_SET = "externalInvoicingId",
  FLOWS_GET = "FLOWS_GET",
  FLOWS_SET = "FLOWS_SET",
  FORM_ERRORS_SET = "FORM_ERRORS_SET",
  FORM_SETTINGS_SET = "FORM_SETTINGS_SET",
  HISTORY_FLOWS_ADD = "HISTORY_FLOWS_ADD",
  INTERNAL_COMMENT_SET = "internal_comments",
  ORDER_SET = "ORDER_SET",
  OWN_INSTALLER_SET = "ownInstaller",
  PLANNING_OPPORTUNITY_SET = "PLANNING_OPPORTUNITY_SET",
  POPULATE_QUESTION = "POPULATE_QUESTION",
  PRICING_PRICE_MAX_SET = "PRICING_PRICE_MAX_SET",
  PRICING_PRICE_MIN_SET = "PRICING_PRICE_MIN_SET",
  PRICING_PRICE_SET = "PRICING_PRICE_SET",
  PRICING_REQUEST_ADD = "PRICING_REQUEST_ADD",
  PRICING_REQUEST_REMOVE = "PRICING_REQUEST_REMOVE",
  PROFESSIONAL_COMMENT_SET = "professional_comment",
  PROJECT_ID_SET = "projectId",
  TRANSLATION_SET = "TRANSLATION_SET",
  WIZARD_ITEM_VISIBLE_SET = "WIZARD_ITEM_VISIBLE_SET",
  WIZARD_ITEMS_SET = "WIZARD_ITEMS_SET";
