/* eslint-disable no-unused-vars */
import useFormState from "../state";

export default function useFormOrder(props, context) {
  const formState = useFormState(props);

  async function processOrderPayload() {
    const file = new File(
      [JSON.stringify(formState.orderIntake.value.order)],
      "order",
      { type: "application/json" }
    );
    formState.orderIntake.value.formData.append("order", file);
  }

  function clearOrderIntakeOrderPayload() {
    formState.orderIntake.value.order = {
      services: [],
      products: [],
      inputs: {},
      customer: {
        salutation: null,
        first_name: null,
        last_name: null,
        company_name: "",
        email: null,
        phone: null,
        mobile_phone: null,
        shipping_address: {
          city: null,
          country_code: null,
          postal_code: null,
          street: null,
        },
        billing_address: {
          city: null,
          country_code: null,
          postal_code: null,
          street: null,
        },
        billing_first_name: null,
        billing_last_name: null,
        billing_company_name: null,
        email_for_invoices: null,
      },
      contact_person: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        use_for_email: null,
        quote_target: null,
      },
      use_contact_person: null,
      opportunity: null,
      professional_comment: null,
      internal_comments: null,
      source: "order-intake-forms",
      referrer: "order-intake-forms",
      affiliate_id: null,
      invoicing_eid: null,
      readiness_date: null,
    };
  }

  return {
    processOrderPayload,
    clearOrderIntakeOrderPayload,
  };
}
