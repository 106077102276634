export default {
  flows: [],
  wizardItems: [],
  opportunity: {},
  errors: [],
  history: {
    flows: [],
  },
  settings: {
    form: {
      name: "gardena",
      progress: false,
      price: true,
      priceEstimateFrom: null,
      summary: null,
      mapping: null,
    },
  },
  translation: {},
  customer: {
    customer_eid: null, // customer card number
    email: null,
    email_for_invoices: null,
    email_for_orders: null,
    phone: null,
    mobile_phone: null,
    salutation: null,
    shipping_address: {
      city: null,
      country_code: null,
      postal_code: null,
      street: null,
    },
  },
  order: {
    id: null,
    services: null,
    completeOrder: null,
  },
  auth: {
    accessToken: null,
  },
  affiliateId: null,
  externalInvoicingId: null,
  customerSignature: null,
  eid: null,
  projectId: null,
  brand2: null,
  ownInstaller: null,
  professional_comment: null,
  pricing: {
    requests: [],
    price: null,
    priceMin: null,
    priceMax: null,
  },
};
