/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import store from "../../store";
import { formatCurrency } from "../../utils";

export default function useFormPricing(props) {
  const pricing = computed(() => {
    return {
      price:
        store.state.form.pricing.price != null
          ? formatCurrency(store.state.form.pricing.price, props.locale)
          : null,
      priceMin:
        store.state.form.pricing.priceMin != null
          ? formatCurrency(store.state.form.pricing.priceMin, props.locale)
          : null,
      priceMax:
        store.state.form.pricing.priceMax != null
          ? formatCurrency(store.state.form.pricing.priceMax, props.locale)
          : null,
    };
  });

  return {
    pricing,
  };
}
