/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import store from "../../store";

export default function useProgress(props) {
  const progressPercent = computed(() => {
    const notAnsweredItems = store.getters.wizardItemsNotAnswered;
    const items = store.getters.allWizardItems;
    return (1 - notAnsweredItems.length / items.length) * 100;
  });

  return {
    progressPercent,
  };
}
