/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";

export default function useFormComment(props) {
  const formState = useFormState(props);

  function processProfessionalComment() {
    const sections = [formState.orderIntake.value.order.professional_comment];
    formState.orderIntake.value.order.professional_comment = sections
      .filter((x) => x)
      .join("\n\n");
  }

  return {
    processProfessionalComment,
  };
}
