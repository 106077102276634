import Vue from "vue";
import VueTagManager from "vue-tag-manager";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import { provide } from "@vue/composition-api";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueGtag from "vue-gtag";

import store from "./store";
import i18n from "./i18n";
import { getEnv } from "./utils";
import { FORM_SETTINGS_SET } from "./store/form.module/types";

import App from "./App.vue";

import "@/assets/scss/styles.scss";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { setVeeValidate } from "./vee-validate";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueTagManager, {
  gtmId: "GTM-T9G49SB", // same as on gid-apps
  // gtmId: 'GTM-PN23LT3', // testing-account (container)
});

setVeeValidate(i18n.locale);

store.subscribe(({ type }) => {
  if (type === FORM_SETTINGS_SET) {
    const locale = store.getters.formSettings.locale;
    i18n.locale = locale;
    setVeeValidate(locale);
  }
});

const nodeEnv = getEnv("NODE_ENV");
if (["production", "staging"].includes(nodeEnv)) {
  Sentry.init({
    Vue,
    dsn: "https://16111a55c1b14ad096863bda468f9ddd@o72657.ingest.sentry.io/5812057",
    integrations: [new Integrations.BrowserTracing()],
    environment: nodeEnv,
    release: nodeEnv,
    tracesSampleRate: ["staging"].includes(nodeEnv) ? 1.0 : 0.3,
  });
}

Vue.use(VueCompositionAPI);

Vue.config.productionTip = false;

Vue.config.devtools = nodeEnv === "development";

if (getEnv("VUE_APP_GA")) {
  Vue.use(VueGtag, {
    config: {
      id: getEnv("VUE_APP_GA"),
      params: {
        anonymize_ip: true,
      },
    },
  });
}

new Vue({
  setup() {
    provide("vuex-store", store);
  },
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
