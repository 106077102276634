/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import store from "../../store";
import i18n from "../../i18n";

export default function useForeword(props) {
  // computed properties
  const formSettings = computed(() => {
    return store.getters.formSettings;
  });

  const foreword = computed(() => {
    return {
      title: i18n.t("foreword.title"),
      summary: i18n.t("foreword.summary"),
      description: i18n.t("foreword.description"),
    };
  });

  return {
    foreword,
  };
}
