<template>
  <div>
    <a
      v-for="(file, index) in files"
      :key="index"
      :href="file.src"
      target="_blank"
      class="d-block p-2 mb-2 text-center border rounded"
    >
      <img v-if="file.src" :src="file.src" class="mw-100" />

      <i v-else class="display-4 text-primary material-symbols-outlined">
        {{ getIconByType(file.type) }}
      </i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
  },
  data() {
    return {
      files: [],
    };
  },
  created() {
    this.files = this.value?.map((file) => {
      if (file.preview) {
        const blob = this.base64ToBlob(file.preview, file.mimetype);
        const src = URL.createObjectURL(blob);
        return { type: file.mimetype, src };
      }

      return { type: file.mimetype };
    });
  },
  destroyed() {
    this.files.forEach((file) => {
      if (file.src) {
        URL.revokeObjectURL(file.src);
      }
    });
  },
  methods: {
    base64ToBlob(base64, type) {
      const binStr = atob(base64);
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      return new Blob([arr], { type });
    },
    getIconByType(mimetype) {
      if (mimetype === "application/pdf") {
        return "picture_as_pdf";
      } else if (mimetype.startsWith("image/")) {
        return "collections";
      }
      return "description";
    },
  },
};
</script>
