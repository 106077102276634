/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import { toRefs } from "@vue/composition-api";
import useFormState from "./state";
import store from "../../store";

export default function useFormHash(props) {
  const formState = useFormState(props);

  function generateHash() {
    formState.orderIntake.value.hash = new Date().getTime().toString(36);
  }

  function clearOrderIntakeHash() {
    formState.orderIntake.value.hash = null;
  }

  return {
    // methods
    generateHash,
    clearOrderIntakeHash,
  };
}
