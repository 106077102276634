/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";
import { CustomerSignatureStatusEnum } from "@gid/models";

export default function useFormPopulatedJobFields(props) {
  const formState = useFormState(props);

  function processPopulatedJobFields() {
    if (store.getters.customerSignature) {
      formState.orderIntake.value.order.inputs.customer_signature =
        CustomerSignatureStatusEnum.TAKEN;
      formState.orderIntake.value.order.inputs.customer_signature_reason = null;
    }

    if (
      store.getters.professional_comment() &&
      store.getters.professional_comment().length > 0
    ) {
      const existingProfessionalComment =
        store.getters.completeOrder?.job?.professional_comment;
      // at least we want to keep the old comment:
      let newProfessionalComment = existingProfessionalComment;
      // if new answer is an extension of the exiting, use just the new answer as merged output:
      if (
        store.getters.professional_comment() &&
        store.getters.professional_comment().includes(newProfessionalComment)
      ) {
        newProfessionalComment = store.getters.professional_comment();
      }
      // if the new answer is not overlapping with the existing, append it to the existing:
      if (
        !newProfessionalComment.includes(store.getters.professional_comment)
      ) {
        newProfessionalComment =
          existingProfessionalComment +
          "\n" +
          store.getters.professional_comment();
      }
      formState.orderIntake.value.order.inputs.professional_comment =
        newProfessionalComment;
    }

    if (
      (store.getters.customer_salutation() ||
        store.getters.customer_first_name() ||
        store.getters.customer_last_name() ||
        store.getters.customer_shipping_street() ||
        store.getters.customer_shipping_postal_code() ||
        store.getters.customer_shipping_city() ||
        store.getters.customer_email_for_orders() ||
        store.getters.customer_email_for_invoices() ||
        store.getters.customer_phone() ||
        store.getters.customer_mobile_phone()) &&
      store.getters.order?.completeOrder?.customer
    ) {
      const existingCustomer = store.getters.completeOrder?.customer;
      formState.orderIntake.value.order.inputs.customer_details = {
        salutation:
          store.getters.customer_salutation() || existingCustomer.salutation,
        first_name:
          store.getters.customer_first_name() || existingCustomer.first_name,
        last_name:
          store.getters.customer_last_name() || existingCustomer.last_name,
        shipping_address: {
          country_code: existingCustomer.shipping_address.country_code,
          postal_code:
            store.getters.customer_shipping_postal_code() ||
            existingCustomer.shipping_address.postal_code,
          street:
            store.getters.customer_shipping_street() ||
            existingCustomer.shipping_address.street,
          city:
            store.getters.customer_shipping_city() ||
            existingCustomer.shipping_address.city,
        },
        email:
          store.getters.customer_email_for_orders() || existingCustomer.email,
        email_for_orders:
          store.getters.customer_email_for_orders() ||
          existingCustomer.email_for_orders,
        email_for_invoices:
          store.getters.customer_email_for_invoices() ||
          existingCustomer.email_for_invoices,
        phone: store.getters.customer_phone() || existingCustomer.phone,
        mobile_phone:
          store.getters.customer_mobile_phone() ||
          existingCustomer.mobile_phone,
      };
    }
  }

  return {
    processPopulatedJobFields,
  };
}
