<template>
  <div v-show="isVisible">
    <b-form-group label-size="lg">
      <template v-slot:label>
        <span v-html="itemTitle"></span
        ><sup v-if="item.required" class="gid-asterisk">*</sup>
      </template>
      <p v-if="item.errors.length" class="lead">
        <span v-for="error in item.errors" :key="error" class="gid-error"
          >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
        >
      </p>
      <p
        v-if="item.explanation"
        v-html="item.explanation"
        class="lead__explanation"
      ></p>

      <signature
        style="margin-right: 2.5em"
        ref="signature"
        v-model="answerWizardItem"
        :signer-name="signerName"
        @input="onSignatureInput"
      >
      </signature>
    </b-form-group>
  </div>
</template>

<script>
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";
import Signature from "@gid/vue-common/components/inputs/Signature.vue";

export default {
  name: "WizardItemSignature",
  mixins: [WizardItemMixin],
  components: {
    Signature,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        let answer = null;
        if (Array.isArray(answerWizardItem) && answerWizardItem.length === 1) {
          let obj = {
            mimetype: answerWizardItem[0].mimetype || "image/svg+xml",
            data: answerWizardItem[0].data,
            extension: "svg",
          };
          answer = this.buildFile(obj);
        }
        this.$store.dispatch(ANSWER_SET, {
          answer: answer,
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
    signerName() {
      // return `${user.contact.first_name} ${user.contact.last_name}`
      return "Partner Test";
    },
  },
  methods: {
    onSignatureInput(value) {
      if (Array.isArray(value) && value.length === 0) {
        return false;
      }
    },
    isBase64(str) {
      try {
        window.atob(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    binaryStringToFile(bstr, filename, mimeType, lastModified) {
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mimeType,
        lastModified: lastModified,
      });
    },
    dataURLtoFile(dataUrl, filename, lastModified) {
      const arr = dataUrl.split(",");
      const mimeType = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      return this.binaryStringToFile(bstr, filename, mimeType, lastModified);
    },
    buildFile(obj) {
      let fileLastModified = new Date();
      let fileType = obj.mimetype;
      let fileData = obj.data;
      let fileExtension = obj.extension;
      let fileName = `${this.item.name}.${fileExtension}`;

      if (this.isBase64(fileData)) {
        const newFile = this.dataURLtoFile(
          `data:${fileType};base64,${fileData}`,
          fileName,
          fileLastModified
        );
        return newFile;
      }
      const newFile = this.binaryStringToFile(
        fileData,
        fileName,
        fileType,
        fileLastModified
      );
      return newFile;
    },
  },
  created() {},
  mounted() {
    this.$refs.signature.resizeCanvas();
  },
};
</script>
