<template>
  <b-row v-if="hasPriceEstimate || hasPrice">
    <span class="ml-auto mb-2 p-2 bg-secondary text-light">
      <template v-if="hasPriceEstimate && hasPriceEstimateMinMax">
        {{ priceEstimateLabel }}: {{ pricing.priceMin }} -
        {{ pricing.priceMax }} €
      </template>
      <template v-else-if="hasPriceEstimate && !hasPriceEstimateMinMax">
        {{ priceEstimateNotAvailableLabel }}
      </template>
      <template v-else-if="hasPrice">
        {{ priceLabel }}: {{ pricing.price }} € {{ grossPriceLabel }}
      </template>
    </span>
  </b-row>
  <b-row v-else-if="hasNoPrice">
    <span
      class="ml-auto mb-2 p-2 bg-secondary text-light"
      v-if="noPricing.length > 0"
    >
      {{ noPricing }}
    </span>
  </b-row>
</template>

<script>
export default {
  name: "WizardPrice",
  components: {},
  props: {
    pricing: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      grossPriceLabel: this.$t(this.$t("form.gross-price-label")),
      noPricing: this.$t(this.$t("form.no-pricing-label")) || "",
      priceEstimateLabel: this.$t(this.$t("form.estimated-price")),
      priceEstimateNotAvailableLabel: this.$t(
        this.$t("form.estimated-price-not-available")
      ),
      priceLabel: this.$t(this.$t("form.price")),
    };
  },
  computed: {
    hasPriceEstimate() {
      return (
        this.$store.getters.formSettings.priceEstimateFrom &&
        this.pricing.priceMin &&
        this.pricing.priceMax
      );
    },
    hasPriceEstimateMinMax() {
      return this.pricing.priceMin && this.pricing.priceMax;
    },
    hasPrice() {
      return !!this.pricing.price;
    },
    hasNoPrice() {
      return this.pricing.price === 0;
    },
  },
};
</script>
