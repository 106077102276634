<template>
  <div v-show="isVisible">
    <b-form-group label-size="lg">
      <template v-slot:label>
        <span v-html="itemTitle"></span
        ><sup v-if="item.required" class="gid-asterisk">*</sup>
      </template>
      <p v-if="item.errors.length" class="lead">
        <span v-for="error in item.errors" :key="error" class="gid-error"
          >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
        >
      </p>
      <file-uploader
        v-model="answerWizardItem"
        :multiple="item.multiple"
        upload-url="/api/form/answer-file"
        delete-url="/api/form/answer-file"
        accept=".jpg, .jpeg"
      ></file-uploader>

      <p v-if="item.explanation" v-html="item.explanation" class="lead"></p>
    </b-form-group>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";
import FileUploader from "@/components/FileUploader";

export default {
  name: "WizardItemFile",
  mixins: [WizardItemMixin],
  components: {
    FileUploader,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem,
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.lead {
  text-align: left;
  margin-top: 0.5rem;

  &__min-width {
    min-width: 312px;
  }
}
</style>
