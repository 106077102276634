<template>
  <div v-if="item.explanation">
    <p
      v-show="isVisible"
      v-html="item.explanation"
      class="lead__explanation"
    ></p>
  </div>
  <div v-else>
    <b-form-group v-show="false" :label="itemTitle" label-size="lg">
      <input
        type="hidden"
        v-model="answerWizardItem"
        :name="item.name"
        @change="changeAction"
      />
    </b-form-group>
  </div>
</template>

<script>
import WizardItemMixin from "@/components/mixins/wizardItem.js";

export default {
  name: "WizardItemHidden",
  mixins: [WizardItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
  },
};
</script>
