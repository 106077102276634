/* eslint-disable no-unused-vars */
import useFormState from "../state";
import useFormCreation from "./";
import axios from "axios";

export default function useFormActions(props, context) {
  const formState = useFormState(props);

  async function submit() {
    formState.buttons.value.submit.loading = true;

    const creation = useFormCreation(props, context);

    await creation.prepareOrderIntakePayload();

    const request = axios.request({
      url: props.form.api.createOrder.url,
      method: props.form.api.createOrder.method,
      data: formState.orderIntake.value.formData,
      headers: props.form.api.createOrder.headers,
    });

    const promise = new Promise((resolve, reject) => {
      request
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          formState.summary.value = false;
          reject(e);
        })
        .finally(() => {
          formState.buttons.value.submit.loading = false;

          creation.clearOrderIntake();

          window.scrollTo(0, 0);
          window.top.postMessage(
            {
              action: "scrollIntoView",
              value: { behavior: "smooth", block: "start" },
            },
            "*"
          );
        });
    });

    context.emit("submit", promise);
  }

  return {
    submit,
  };
}
