/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";

export default function useFormAffiliate(props) {
  const formState = useFormState(props);

  function processAffiliate() {
    formState.orderIntake.value.order.affiliate_id =
      store.getters.affiliateId || null;
  }

  return {
    processAffiliate,
  };
}
