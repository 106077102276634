/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import i18n from "../../i18n";
import store from "../../store";

const ELECTRIC_STYLE_DIY_WALLBOX_INSTALLATIONEN_OPP = "0060800000uC6hVAAS";

export default function useAfterword(props) {
  const afterword = computed(() => {
    let success = {
      manualProcessing: i18n.te("success.manualProcessing")
        ? i18n.t("success.manualProcessing")
        : i18n.t("success.text"),
      immediateProcessing: i18n.te("success.immediateProcessing")
        ? i18n.t("success.immediateProcessing")
        : i18n.t("success.text"),
    };

    if (
      store.getters.opportunity.sfid ===
      ELECTRIC_STYLE_DIY_WALLBOX_INSTALLATIONEN_OPP
    ) {
      success.immediateProcessing = `
      Wir haben Ihre Daten für die Ladestation erhalten. Vielen Dank.
      Damit sind Sie nur noch einen Schritt entfernt von Ihrer persönlichen Ladestation.
      Einer unserer Experten geht nun alle Informationen durch und stellt ein auf Sie zugeschnittenes Angebot zusammen. Sollten hierbei noch Rückfragen aufkommen, melden wir uns bei Ihnen.
      Wir werden Ihnen dann das Angebot per Email zusenden. Für gewöhnlich dauert dies ca. 2-3 Werktage.
      `;
    }

    let message = success.immediateProcessing;

    return {
      title: i18n.t("afterword.title"),
      message: message,
      summary: i18n.t("afterword.summary"),
      description: i18n.t("afterword.description"),
      success: success,
    };
  });

  return {
    afterword,
  };
}
