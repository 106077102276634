/* eslint-disable no-unused-vars */
import useFormState from "./state";
import store from "../../store";
import { computed } from "@vue/composition-api";

export default function useFormAnswers(props, context) {
  const formState = useFormState(props);

  const currentFlow = computed(() => {
    return store.getters.flows.find(
      (flow) => flow.step === formState.flowStep.value
    );
  });

  function processAnswersPayload() {
    const flowFilter = (item) => {
      return !context.preSave || item.flowId === currentFlow.value.id;
    };

    let answers = [];

    answers.push(
      ...store.getters.wizardNonFileTypeItems.filter(flowFilter).map((item) => {
        return {
          answer: item.answer,
          questionId: item.id,
        };
      })
    );

    answers.push(
      ...store.getters.wizardFileTypeItems
        .filter(flowFilter)
        .map((item) => {
          if (item.answer) {
            return item.answer.map((x) => ({
              answer: x.key,
              questionId: item.id,
            }));
          }

          return {
            answer: item.answer,
            questionId: item.id,
          };
        })
        .flat()
    );

    const file = new File([JSON.stringify(answers)], "answers", {
      type: "application/json",
    });
    formState.orderIntake.value.formData.append("answers", file);
  }

  return {
    processAnswersPayload,
  };
}
