<template>
  <div v-show="isVisible">
    <validation-provider
      ref="vp"
      :name="itemTitle"
      :rules="rules"
      v-slot="validationContext"
      :mode="validationMode"
    >
      <b-form-group label-size="lg">
        <template v-slot:label>
          <span v-html="itemTitle"></span
          ><sup v-if="item.required" class="gid-asterisk">*</sup>
        </template>
        <p v-if="item.errors.length" class="lead">
          <span v-for="error in item.errors" :key="error" class="gid-error"
            >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
          >
        </p>
        <p
          v-if="item.explanation"
          v-html="item.explanation"
          class="lead__explanation"
        ></p>
        <b-form-input
          v-if="!hasStored"
          v-model="answerWizardItem"
          debounce="300"
          :type="item.type"
          :name="item.name"
          :state="getValidationState(validationContext)"
          :aria-describedby="String(item.id)"
          @change="changeAction"
        >
        </b-form-input>
        <p v-else class="lead"><span v-text="answerWizardItem"></span></p>
        <b-form-invalid-feedback :id="String(item.id)">{{
          validationContext.errors[0]
        }}</b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";

const reservedNames = ["email"];

export default {
  name: "WizardItemEmail",
  mixins: [WizardItemMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasStored() {
      return (
        !!this.$store.state.form.customer?.email &&
        reservedNames.includes(this.item.name)
      );
    },
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.item.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem.toLowerCase(),
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
    rules() {
      // remoteEmail rule must be last (order matters)
      return {
        required: this.item.required,
        email: true,
        remoteEmail: true,
      };
    },
    validationProvider() {
      return this.$refs[`vp-${this.item.id}`];
    },
  },
  methods: {
    validationMode() {
      return { on: ["input"] };
    },
  },
  created() {
    if (this.hasStored) {
      this.answerWizardItem = this.$store.state.form.customer.email;
    }
  },
  mounted() {
    this.$watch(
      () => {
        return this.$refs.vp.errors;
      },
      (val) => {
        this.item.feErrors = val || [];
      }
    );
  },
};
</script>
