<template>
  <div>
    <component
      :is="componentType"
      :item="item"
      :processPricing="processPricing"
    />
  </div>
</template>

<script>
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemRadio from "@/components/WizardItemRadio.vue";
import WizardItemSelect from "@/components/WizardItemSelect.vue";
import WizardItemText from "@/components/WizardItemText.vue";
import WizardItemPhone from "@/components/WizardItemPhone.vue";
import WizardItemHidden from "@/components/WizardItemHidden.vue";
import WizardItemTextarea from "@/components/WizardItemTextarea.vue";
import WizardItemEmail from "@/components/WizardItemEmail.vue";
import WizardItemNumber from "@/components/WizardItemNumber.vue";
import WizardItemTimeInterval from "@/components/WizardItemTimeInterval.vue";
import WizardItemCheckbox from "@/components/WizardItemCheckbox.vue";
import WizardItemSwitch from "@/components/WizardItemSwitch.vue";
import WizardItemFile from "@/components/WizardItemFile.vue";
import WizardItemDate from "@/components/WizardItemDate.vue";
import WizardItemSignature from "@/components/WizardItemSignature.vue";
import WizardItemPostalCode from "@/components/WizardItemPostalCode.vue";

export default {
  name: "WizardItem",
  components: {
    WizardItemRadio,
    WizardItemSelect,
    WizardItemText,
    WizardItemPhone,
    WizardItemHidden,
    WizardItemTextarea,
    WizardItemEmail,
    WizardItemNumber,
    WizardItemTimeInterval,
    WizardItemCheckbox,
    WizardItemSwitch,
    WizardItemFile,
    WizardItemDate,
    WizardItemSignature,
    WizardItemPostalCode,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    answerWizardItem: {
      get() {
        return this.$store.getters.getAnswer(this.id);
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem,
          id: this.id,
          errors: [],
        });
      },
    },
    item() {
      return this.$store.getters.wizardItemById(this.id);
    },
    componentType() {
      let component = this.item.type;
      component = component.replace("_", "-");
      if (component.startsWith("select")) {
        component = "select";
      }
      return `wizard-item-${component}`;
    },
  },
  methods: {},
  created() {},
};
</script>
<style>
/* class="gid-error" */
.gid-error {
  color: red;
}

.gid-asterisk {
  color: red;
  margin: 0 0.1rem;
  font-size: 1.1rem;
}
</style>
