/* eslint-disable no-unused-vars */
import store from "../../../store";

// common
import useFormMapping from "../mapping";
import useFormAnswers from "../answers";
import useFormMetadata from "../metadata";
import useFormHash from "../hash";
import useFormOrderBase from "../order";

// creation
import useFormCustomer from "./customer";
import useFormProject from "./project";
import useFormAffiliate from "./affiliate";
import useFormPopulatedJobFields from "./populated-job-fields";
import useFormComment from "./comment";
import useFormOpportunity from "./opportunity";
import useFormOrder from "./order";

export default function useFormCreation(props, context) {
  const { generateHash, clearOrderIntakeHash } = useFormHash(props);

  const { processCustomerData, processContactPersonData } =
    useFormCustomer(props);

  const { processProject } = useFormProject(props);

  const { processAffiliate } = useFormAffiliate(props);

  const { processPopulatedJobFields } = useFormPopulatedJobFields(props);

  const { processOpportunity } = useFormOpportunity(props);

  const { processAnswersPayload } = useFormAnswers(props, context);

  const { processMetadataPayload, processFilesMetadataPayload } =
    useFormMetadata(props, context);

  const { processOrderPayload, clearOrderIntakeOrderPayload } = useFormOrder(
    props,
    context
  );

  const { clearOrderIntakeFormData } = useFormOrderBase(props, context);

  const { processProfessionalComment } = useFormComment(props);

  const { addService, addProduct, addProfessionalComment } =
    useFormMapping(props);

  // methods
  async function prepareOrderIntakePayload() {
    sanitizeOrderIntakePayload();
    clearOrderIntake();
    generateHash();
    processCustomerData();
    processContactPersonData();
    await props.processMapping({
      addService,
      addProduct,
      addProfessionalComment,
    });
    processOpportunity();
    processProfessionalComment();
    processProject();
    processAffiliate();
    processPopulatedJobFields();
    await processOrderPayload();
    processAnswersPayload();
    processMetadataPayload();
    processFilesMetadataPayload();
  }

  function sanitizeOrderIntakePayload() {
    const data = store.getters.allWizardItems;
    const postalCodeIndex = data.findIndex((x) => x.name === "postal_code");
    if (postalCodeIndex > -1) {
      data[postalCodeIndex].answer = data[postalCodeIndex].answer?.replace(
        /\s/g,
        ""
      );
    }
  }

  function clearOrderIntake() {
    clearOrderIntakeHash();
    clearOrderIntakeOrderPayload();
    clearOrderIntakeFormData();
  }

  return {
    // methods
    prepareOrderIntakePayload,
    clearOrderIntake,
  };
}
