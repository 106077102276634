import { buildContextForFormula } from "../../utils";

export default {
  allWizardItems(state) {
    return state.wizardItems;
  },
  flows(state) {
    return state.flows;
  },
  allWizardItemsByOpportunitySfid(state, getters) {
    return state.wizardItems.filter((wizardItem) => {
      return wizardItem.opportunity === getters.opportunity.sfid;
    });
  },
  wizardItemByName(state, getters) {
    return (name) => {
      return state.wizardItems.find((wizardItem) => {
        return (
          wizardItem.opportunity === getters.opportunity.sfid &&
          name === wizardItem.name
        );
      });
    };
  },
  wizardItemsForDependsOn(state, getters) {
    return getters.allWizardItemsByOpportunitySfid.reduce((acc, item) => {
      acc[item.name] = {
        value: item.answer,
      };
      return acc;
    }, {});
  },
  wizardItemById(state, getters) {
    return (id) => {
      return state.wizardItems.find((wizardItem) => {
        return (
          wizardItem.opportunity === getters.opportunity.sfid &&
          id === wizardItem.id
        );
      });
    };
  },
  wizardItemsByFlow(state, getters) {
    return (flowId, { requiredOnly, answeredOnly }) => {
      // eslint-disable-line
      return state.wizardItems.filter((wizardItem) => {
        let requiredOnlyCondition = true;
        if (requiredOnly) {
          requiredOnlyCondition = wizardItem.required === true;
        }
        let answeredOnlyCondition = true;
        if (answeredOnly) {
          answeredOnlyCondition = !(
            wizardItem.answer === "" ||
            !wizardItem.answer ||
            ["nein", "false"].includes(
              wizardItem.answer.toString().toLowerCase()
            )
          );
        }
        return (
          wizardItem.opportunity === getters.opportunity.sfid &&
          wizardItem.flowId === flowId &&
          requiredOnlyCondition &&
          answeredOnlyCondition
        );
      });
    };
  },
  wizardItemDependsOnSomething(state, getters) {
    return (id) => {
      const item = getters.wizardItemById(id);
      let result = true;
      if (item?.dependsOn) {
        const items = getters.wizardItemsForDependsOn;
        const opportunity = getters.opportunityForDependsOn;
        const services = getters.servicesForDependsOn;
        const products = getters.productsForDependsOn;
        const completeOrder = getters.completeOrder;
        const fullContext = buildContextForFormula({
          items,
          opportunity,
          services,
          products,
          completeOrder,
        });
        const evalDependsOn = new Function(
          "{context, answer, opportunity, service, product, completeOrder}",
          `return ${item.dependsOn}`
        );
        result = evalDependsOn(fullContext);
      }
      return Boolean(result);
    };
  },
  getAnswer(state) {
    return (id) => {
      const item = state.wizardItems.find((x) => x.id === id);
      if (!item) {
        return "";
      }
      return item.answer;
    };
  },
  wizardItemsNotAnswered(state) {
    return state.wizardItems.filter((wizardItem) => {
      if (wizardItem.type === "checkbox") {
        // TODO: keep as a reference to be used later on a "radio" question type | no data migration for checkbox
        if (
          wizardItem.metadata &&
          wizardItem.metadata["validate-only-answer"]
        ) {
          return (
            wizardItem.answer !==
            String(wizardItem.metadata["validate-only-answer"])
          );
        } else {
          if (wizardItem.answer) {
            return wizardItem.answer === "false";
          }
          return true;
        }
      } else if (
        wizardItem.type === "phone" ||
        (wizardItem.type === "input" && wizardItem.name === "phone")
      ) {
        if (wizardItem.answer) {
          return wizardItem.answer.length <= 4;
        }
        return true;
      } else if (wizardItem.type === "file") {
        return Array.from(wizardItem.answer || []).length === 0;
      }
      return wizardItem.answer === "" || !wizardItem.answer;
    });
  },
  wizardItemsNotAnsweredOrWithErrors() {
    return (wizardItems) => {
      return wizardItems.filter((wizardItem) => {
        let answer = wizardItem.answer === "" || !wizardItem.answer;
        if (wizardItem.type === "file" || wizardItem.type === "select") {
          if (
            Array.isArray(wizardItem.answer) &&
            !Array.from(wizardItem.answer).length
          ) {
            answer = true;
          }
        } else if (wizardItem.type === "checkbox") {
          // TODO: keep as a reference to be used later on a "radio" question type | no data migration for checkbox
          if (
            wizardItem.metadata &&
            wizardItem.metadata["validate-only-answer"]
          ) {
            answer =
              wizardItem.answer !==
              String(wizardItem.metadata["validate-only-answer"]);
          } else {
            if (wizardItem.answer) {
              answer = wizardItem.answer === "false";
            } else {
              answer = true;
            }
          }
        } else if (wizardItem.type === "phone") {
          if (wizardItem.answer) {
            answer = wizardItem.answer.length <= 4;
          } else {
            answer = true;
          }
        }

        let error = true;
        if (
          Object.keys(wizardItem).includes("feErrors") &&
          Array.isArray(wizardItem.feErrors)
        ) {
          if (wizardItem.feErrors.length === 0) {
            error = false;
          }
        } else {
          error = false;
        }
        return answer || error;
      });
    };
  },
  visibleWizardItemsNotAnswered(state, getters) {
    return (currentFlow) => {
      const items = getters.wizardItemsByFlow(currentFlow.id, {
        requiredOnly: true,
      });
      const visibleItems = items.filter((item) => item.visible === true);
      return getters.wizardItemsNotAnswered.filter((item) =>
        visibleItems.some((visibleItem) => visibleItem.id === item.id)
      );
    };
  },
  hasAllAnsweredFlowItems(state, getters) {
    return (currentFlow) => {
      const items = getters.wizardItemsByFlow(currentFlow.id, {
        requiredOnly: true,
      });
      const visibleItems = items.filter((item) => item.visible);
      return (
        getters.wizardItemsNotAnsweredOrWithErrors(visibleItems)?.length === 0
      );
    };
  },
  hasLoadingFileTypeItems(state, getters) {
    return (
      getters.wizardFileTypeItems.filter((wizardItem) => {
        return wizardItem.answer?.some((file) => file.loading);
      }).length > 0
    );
  },
  wizardFileTypeItems(state) {
    return state.wizardItems.filter((wizardItem) => {
      return wizardItem.type === "file";
    });
  },
  wizardNonFileTypeItems(state) {
    return state.wizardItems.filter((wizardItem) => {
      return wizardItem.type !== "file";
    });
  },
  wizardSignatureTypeItems(state) {
    return state.wizardItems.filter((wizardItem) => {
      return wizardItem.type === "signature";
    });
  },
  ifRequiredWizardItemsAnswered(state, getters) {
    return (
      getters.wizardItemsNotAnswered.filter(
        (wizardItem) => wizardItem.visible && wizardItem.required
      ).length === 0
    );
  },
  flowsByOpportunitySfid(state, getters) {
    return state.flows.filter((flow) => {
      return flow.opportunity === getters.opportunity.sfid;
    });
  },
  flowHasVisibleItems(state, getters) {
    return (flowId) => {
      try {
        return getters
          .wizardItemsByFlow(flowId, {})
          .some((item) => getters.wizardItemDependsOnSomething(item.id));
      } catch (error) {
        console.error("Error in flowHasVisibleItems:", error);
        return false;
      }
    };
  },
  getFlowByOpportunitySfidAndStep(state, getters) {
    return (step) => {
      return state.flows.find((flow) => {
        return (
          flow.opportunity === getters.opportunity.sfid && flow.step === step
        );
      });
    };
  },
  getDependentItemsOf(state) {
    return (item) => {
      return state.wizardItems.filter((x) => {
        return x.dependsOn &&
          x.dependsOn.items &&
          Object.keys(x.dependsOn.items).find((y) => y === item.id)
          ? true
          : false;
      });
    };
  },
  earliestFlowWithErrors(state) {
    const flowIdsWithErrors = state.wizardItems
      .filter((x) => Array.isArray(x.errors) && x.errors.length)
      .map((x) => x.flowId);
    if (flowIdsWithErrors) {
      const flows = state.flows
        .filter((x) => flowIdsWithErrors.includes(x.id))
        .sort((x) => x.step);
      if (flows.length) {
        return flows[0];
      }
    }
    return null;
  },
  isFirstFlow(state, getters) {
    return (step) => {
      return getters.flowsByOpportunitySfid[0].step === step;
    };
  },
  isLastFlow(state, getters) {
    return (step) => {
      return (
        getters.flowsByOpportunitySfid[
          getters.flowsByOpportunitySfid.length - 1
        ].step === step
      );
    };
  },
  wizardItemsForSummary(state, getters) {
    const wizardItemsGroupedByFlow = getters.allWizardItemsByOpportunitySfid
      .filter((item) => item.answer && item.type !== "hidden")
      .reduce(function (r, a) {
        r[a.flowId] = r[a.flowId] || [];
        r[a.flowId].push(a);
        r[a.flowId].sort((x, y) => x.sort - y.sort);
        return r;
      }, Object.create(null));
    return Object.keys(wizardItemsGroupedByFlow).flatMap(
      (x) => wizardItemsGroupedByFlow[x]
    );
  },
  servicesForDependsOn(state) {
    return (state.order.services || []).reduce((acc, service) => {
      acc[service.service] = {
        apiId: service.id,
        jobApiId: service.job_id,
        quantity: service.quantity,
      };
      return acc;
    }, {});
  },
  completeOrder(state) {
    return state.order.completeOrder;
  },
  productsForDependsOn(state) {
    return (state.order.products || []).reduce((acc, product) => {
      acc[product.product] = {
        id: product.id,
        jobApiId: product.job_api_id,
        quantity: product.quantity,
      };
      return acc;
    }, {});
  },
  opportunity(state) {
    return state.opportunity;
  },
  opportunityForDependsOn(state) {
    return {
      ...state.opportunity,
      tags: state.opportunity.tags.reduce((acc, tag) => {
        acc[tag.tag] = true;
        return acc;
      }, {}),
    };
  },
  formSettings(state) {
    return state.settings.form;
  },
  order(state) {
    return state.order;
  },
  accessToken(state) {
    return state.auth.accessToken;
  },
  affiliateId(state) {
    return state.affiliateId;
  },
  externalInvoicingId(state) {
    return state.externalInvoicingId;
  },
  customerSignature(state) {
    return state.customerSignature;
  },
  eid(state) {
    return state.eid;
  },
  customerEid(state) {
    return state.customer.customer_eid;
  },
  projectId(state) {
    return state.projectId;
  },
  brand2(state) {
    return state.brand2;
  },
  internal_comments(state) {
    return state.internal_comments;
  },
  professional_comment: (state) => () => {
    return state.professional_comment;
  },
  ownInstaller(state) {
    return state.ownInstaller;
  },
  customer_email_for_orders: (state) => () => {
    return state.customer.email_for_orders;
  },
  customer_email_for_invoices: (state) => () => {
    return state.customer.email_for_invoices;
  },
  customer_phone: (state) => () => {
    return state.customer.phone;
  },
  customer_mobile_phone: (state) => () => {
    return state.customer.mobile_phone;
  },
  customer_first_name: (state) => () => {
    return state.customer.first_name;
  },
  customer_last_name: (state) => () => {
    return state.customer.last_name;
  },
  customer_salutation: (state) => () => {
    return state.customer.salutation;
  },
  customer_shipping_street: (state) => () => {
    return state.customer.shipping_address.street;
  },
  customer_shipping_country_code: (state) => () => {
    return state.customer.shipping_address.country_code;
  },
  customer_shipping_postal_code: (state) => () => {
    return state.customer.shipping_address.postal_code;
  },
  customer_shipping_city: (state) => () => {
    return state.customer.shipping_address.city;
  },
};
