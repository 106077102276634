/* eslint-disable no-unused-vars */
import { computed, reactive } from "@vue/composition-api";
import { toRefs } from "@vue/composition-api";
import useFormState from "./state";
import store from "../../store";

export default function useFormActions(props, context) {
  const formState = useFormState(props);

  const currentFlow = computed(() => {
    return store.getters.flows.find((x) => x.step === formState.flowStep.value);
  });

  const flows = computed(() => {
    return store.getters.flows;
  });

  async function nextFlowStep() {
    formState.buttons.value.next.loading = true;

    context.root.$gtm.push({
      event: "flow",
      form: props.form.id,
      headline: currentFlow.value.headline,
      step: formState.flowStep.value + 1,
      direction: "next",
    });

    if (formState.flowStep.value < flows.value.length) {
      if (formState.flowStep.value + 1 < flows.value.length) {
        const has = store.getters.flowHasVisibleItems(
          flows.value[formState.flowStep.value + 1].id
        );
        // console.log('check next...', flows.value[formState.flowStep.value + 1].headline, has)
        if (has) {
          // console.log('yes, it has visible items for', flows.value[formState.flowStep.value + 1].id, flows.value[formState.flowStep.value + 1].headline)
          formState.flowStep.value++;
        } else {
          // console.log('no....', flows.value[formState.flowStep.value + 1].id, flows.value[formState.flowStep.value + 1].headline)
          formState.flowStep.value++;
          nextFlowStep();
        }
      }
    }
    if (props.processPricing) {
      props.processPricing();
    }

    formState.buttons.value.next.loading = false;

    window.scrollTo(0, 0);
    window.top.postMessage(
      {
        action: "scrollIntoView",
        value: { behavior: "smooth", block: "start" },
      },
      "*"
    );
  }

  async function previousFlowStep() {
    formState.buttons.value.previous.loading = true;

    context.root.$gtm.push({
      event: "flow",
      form: props.form.id,
      step: formState.flowStep.value - 1,
      headline: currentFlow.value.headline,
      direction: "previous",
    });

    if (formState.flowStep.value > 0) {
      if (formState.flowStep.value - 1 >= 0) {
        const has = store.getters.flowHasVisibleItems(
          flows.value[formState.flowStep.value - 1].id
        );
        // console.log('check previous...', flows.value[formState.flowStep.value - 1].headline, has)
        if (has) {
          // console.log('yes, it has visible items for', flows.value[formState.flowStep.value - 1].id, flows.value[formState.flowStep.value - 1].headline)
          formState.flowStep.value--;
        } else {
          // console.log('no....', flows.value[formState.flowStep.value - 1].id, flows.value[formState.flowStep.value - 1].headline)
          formState.flowStep.value--;
          previousFlowStep();
        }
      }
    }

    if (props.processPricing) {
      props.processPricing();
    }

    formState.buttons.value.previous.loading = false;

    window.scrollTo(0, 0);
    window.top.postMessage(
      {
        action: "scrollIntoView",
        value: { behavior: "smooth", block: "start" },
      },
      "*"
    );
  }

  return {
    previousFlowStep,
    nextFlowStep,
  };
}
