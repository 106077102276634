import Vue from "vue";
import {
  ValidationProvider,
  ValidationObserver,
  extend,
  localize,
} from "vee-validate";
import {
  is,
  required,
  length,
  email,
  min,
  max,
  regex,
  min_value,
  max_value,
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
import de from "vee-validate/dist/locale/de.json";
import fr from "vee-validate/dist/locale/fr.json";
import ValidatingGroup from "@gid/vue-common/components/ValidatingGroup";
import axios from "axios";
import i18n from "./i18n";

extend("is", is);
extend("required", required);
extend("email", email);
extend("min", min);
extend("max", max);
extend("length", length);
extend("regex", regex);
extend("max_value", max_value);
extend("min_value", min_value);
extend("postalCode", {
  params: ["countryCode"],
  // keep as function to allow i18n to be used
  message: () => i18n.t("errors.postal_code.invalid_postal_code"),
  validate: async (value, { countryCode }) => {
    try {
      const { data } = await axios.request({
        url: `/api/customer/address/country/${countryCode}/postal-code/${value}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      return data.valid;
    } catch (error) {
      return true;
    }
  },
});
extend("remoteEmail", {
  message: () => i18n.t("errors.email.invalid_email"),
  validate: async (value) => {
    try {
      const { data } = await axios.request({
        url: "/api/customer/validation/email",
        method: "POST",
        data: { email: value },
        headers: {
          "Content-Type": "application/json",
        },
      });

      return data.result;
    } catch (error) {
      return true;
    }
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("validating-group", ValidatingGroup);

export function setVeeValidate(locale) {
  const allowedLocales = { en, de, fr };

  if (Object.keys(allowedLocales).includes(locale)) {
    localize(locale, allowedLocales[locale]);
  } else {
    localize("de", de);
  }
}
