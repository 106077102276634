<template>
  <b-container fluid class="gid-form">
    <b-jumbotron v-if="hasIdentification" header-level="6">
      <template #header>{{ $t("identification.header") }}</template>
      <template #lead>{{ $t("identification.lead") }}</template>
      <hr class="my-4" />

      <div v-if="identificationForm.loading" class="mb-4">
        {{ $t("identification.loading") }} <b-spinner />
      </div>
      <template v-else>
        <b-form-group
          v-for="(field, index) in formFields"
          :key="index"
          :label="$t(`identification.fields.${field.name}.label`)"
          label-size="lg"
        >
          <b-form-input
            :type="field.type"
            :name="field.name"
            v-model.trim="inputs[field.name]"
          >
          </b-form-input>
          <p class="lead">
            {{ $t(`identification.fields.${field.name}.explanation`) }}
          </p>
        </b-form-group>
      </template>

      <div class="buttons-container">
        <b-button-group>
          <b-button
            v-if="!proofIdentityLoading"
            variant="primary"
            @click="proofIdentity"
          >
            {{ $t("identification.buttons.proof.name") }}
          </b-button>
          <b-button v-else disabled variant="primary" @click="proofIdentity">
            <b-spinner small></b-spinner>
            {{ $t("identification.buttons.proof.name") }}
          </b-button>
        </b-button-group>
      </div>

      <b-alert
        v-if="proofIdentityFailure"
        show
        variant="danger"
        class="mt-4 text-center"
      >
        {{ $t("identification.failure.proof.message") }}
      </b-alert>
      <div class="mt-3" v-html="footerHtml()"></div>
      <div class="mt-4" v-if="dataPrivacyDefined">
        {{ $t("data-privacy.info") }}
        <a
          href="https://www.getitdone.rocks/datenschutz"
          target="_blank"
          style="text-decoration: underline"
        >
          {{ $t("data-privacy.link-text") }}</a
        >
      </div>
    </b-jumbotron>
    <template v-else>
      <h1>{{ $t("fetch_error") }}</h1>
    </template>
  </b-container>
</template>

<script>
import axios from "axios";
import { CUSTOMER_SET } from "@/store/form.module/types";
import i18n from "../i18n";

export default {
  name: "Identification",
  props: {
    formRef: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      inputs: {},
      identificationForm: {
        loading: false,
        fields: [],
      },
      form: null,
      proofIdentityLoading: false,
      proofIdentityFailure: false,
      api: {
        describeIdentification: {
          url: `/api/form/auth/identification`,
          headers: {
            "Content-Type": "application/json",
          },
        },
        proofIdentity: {
          url: `/api/form/auth/identification`,
          headers: {
            "Content-Type": "application/json",
          },
        },
      },
    };
  },
  computed: {
    dataPrivacyDefined() {
      const value = this.$i18n.t("data-privacy.info");
      return value !== "" && value !== "data-privacy.info";
    },
    formFields() {
      return this.identificationForm.fields.filter(this.presentField);
    },
    hasIdentification() {
      return this.identificationForm.fields.length > 0;
    },
  },
  methods: {
    footerHtml() {
      return this.$i18n.t("identification.footer");
    },
    presentField(field) {
      if (Object.keys(field).includes("dependsOn")) {
        const conditions = [];
        Object.keys(field.dependsOn).forEach((depFieldName) => {
          field.dependsOn[depFieldName].conditions.forEach((depCondition) => {
            if (Object.keys(depCondition).includes("answerRegex")) {
              const depFieldRegex = new RegExp(depCondition.answerRegex);
              conditions.push(
                depFieldRegex.test(String(this.inputs[depFieldName]))
              );
            } else if (Object.keys(depCondition).includes("answer")) {
              conditions.push(
                depCondition.answer === this.inputs[depFieldName]
              );
            }
          });
        });
        return conditions.every((x) => x === true);
      }
      return true;
    },
    async describeIdentification() {
      try {
        this.identificationForm.loading = true;
        const response = await axios.get(this.api.describeIdentification.url, {
          headers: this.api.describeIdentification.headers,
          params: {
            form: this.formRef,
            locale: this.locale,
          },
        });
        this.identificationForm.fields = response.data.fields;
        this.inputs = response.data.fields.reduce((acc, field) => {
          acc[field.name] = null;
          return acc;
        }, {});
      } finally {
        this.identificationForm.loading = false;
      }
    },
    async fetchForm() {
      this.identificationForm.loading = true;
      const response = await axios.get(
        `/api/form/${this.formRef}/locale/${this.locale}`
      );
      this.form = response.data;
      this.identificationForm.loading = false;
    },
    setTranslations() {
      if (this.form) {
        i18n.mergeLocaleMessage(
          this.form.translations[0].locale,
          this.form.translations[0].content
        );
      }
    },
    async proofIdentity() {
      try {
        this.proofIdentityLoading = true;
        this.proofIdentityFailure = false;
        const payload = {
          form: this.formRef,
          locale: this.locale,
          inputs: this.inputs,
        };
        const response = await axios.post(this.api.proofIdentity.url, payload, {
          headers: this.api.proofIdentity.headers,
        });
        this.proofIdentityFailure = !response.data.valid;

        if (response.data.valid) {
          this.$store.dispatch(CUSTOMER_SET, {
            email: response.data.result.email,
            customer_eid: response.data.result.cardNumber,
          });
          this.$emit("proofIdentity", response.data);
        } else {
          this.proofIdentityFailure = false;
        }
      } catch (error) {
        this.proofIdentityFailure = true;
      } finally {
        this.proofIdentityLoading = false;
        window.scrollTo(0, 0);
        window.top.postMessage(
          {
            action: "scrollIntoView",
            value: { behavior: "smooth", block: "start" },
          },
          "*"
        );
      }
    },
  },
  async created() {
    await this.fetchForm();
    this.setTranslations();
    await this.describeIdentification();
  },
};
</script>
