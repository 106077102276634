<template>
  <b-container fluid class="gid-form">
    <template v-if="formFetched">
      <foreword
        v-if="!form.started && !form.finished"
        :title="foreword.title"
        :extra="foreword.extra"
        @getStarted="getStarted"
      ></foreword>
      <wizard-form
        v-if="form.started && !form.finished"
        :form="form"
        :processMapping="processMapping"
        :processPricing="showPrice ? processPricing : null"
        :title="form.name"
        @submit="submit"
      ></wizard-form>
      <afterword
        v-if="!form.started && form.finished"
        :title="afterword.title"
        :message="afterword.message"
        :order="order"
        @finish="finish"
      />
    </template>
    <template v-else-if="form.fetched.loading">
      <h1><b-spinner />{{ $t("loading") }}</h1>
    </template>
    <template v-else>
      <h1>{{ $t("fetch_error") }}</h1>
    </template>
  </b-container>
</template>

<script>
/* eslint-disable no-unused-vars */
import "@/assets/scss/styles.scss";

import Afterword from "@/components/Afterword.vue";
import WizardForm from "@/components/WizardForm.vue";
import { onMounted, ref, inject } from "@vue/composition-api";

export default {
  components: {
    Afterword,
    WizardForm,
  },
  props: {
    id: String,
    formRef: String,
    opportunityId: String,
    locale: String,
    orderId: {
      type: String,
      required: false,
    },
    accessToken: {
      type: String,
      required: false,
      default: null,
    },
    affiliateId: {
      type: String,
      required: false,
      default: null,
    },
    externalInvoicingId: {
      type: String,
      required: false,
      default: null,
    },
    eid: {
      type: String,
      required: false,
      default: null,
    },
    projectId: {
      type: String,
      required: false,
      default: null,
    },
    brand2: {
      type: String,
      required: false,
      default: null,
    },
    ownInstaller: {
      type: String,
      required: false,
      default: null,
    },
    routeParams: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props) {
    const store = inject("vuex-store");

    const useModuleState = require("../composables/gid/state").default;
    const useMapping = require("../composables/gid/mapping").default;
    const useForeword = require("../composables/gid/foreword").default;
    const useAfterword = require("../composables/gid/afterword").default;
    const useForm = require("../composables/gid").default;

    const moduleState = useModuleState(props);

    const {
      isMappingFormula,
      showPrice,
      getMappingItems,
      processMapping,
      processPricing,
      unsubscribePricingRequests,
    } = useMapping(props);

    const { foreword, forewordExtra } = useForeword(props);

    const { afterword } = useAfterword(props);

    const { formFetched, formSettings, fetchForm, getStarted, finish, submit } =
      useForm(props);

    onMounted(() => {
      fetchForm();
    });

    return {
      ...moduleState,
      unsubscribePricingRequests,
      isMappingFormula,
      showPrice,
      foreword,
      forewordExtra,
      afterword,
      formFetched,
      formSettings,
      getStarted,
      finish,
      submit,
      getMappingItems,
      processMapping,
      processPricing,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/styles.scss";
</style>
