<template>
  <div v-show="isVisible">
    <validation-provider
      ref="vp"
      :name="itemTitle"
      :rules="rules"
      v-slot="validationContext"
    >
      <b-form-group label-size="lg">
        <template v-slot:label>
          <span v-html="itemTitle"></span
          ><sup v-if="item.required" class="gid-asterisk">*</sup>
        </template>
        <p v-if="item.errors.length" class="lead">
          <span v-for="error in item.errors" :key="error" class="gid-error"
            >{{ $t(`errors.${item.name}.${error}`) }}&nbsp;</span
          >
        </p>
        <p
          v-if="item.explanation"
          v-html="item.explanation"
          class="lead__explanation"
        ></p>

        <b-input-group>
          <template #prepend>
            <b-dropdown variant="outline-primary">
              <template #button-content>
                <span class="dialing-country__selected">
                  <country-flag
                    :country="country.code"
                    class="dialing-country__selected__flag"
                  />
                  <span class="dialing-country__selected__prefix"
                    >( {{ country.dialing }} )</span
                  >
                </span>
              </template>
              <b-dropdown-item
                @click="selectCountry(country)"
                v-for="country in countries"
                :key="country.name"
                :value="country.code"
              >
                <div>
                  <country-flag :country="country.code" />
                  <span class="ml-2"
                    >{{ country.name }} ( {{ country.dialing }} )</span
                  >
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-form-input
            v-model="answerWizardItem"
            type="text"
            :name="item.name"
            :state="getValidationState(validationContext)"
            :aria-describedby="String(item.id)"
            @blur="phoneBlurAction"
            @change="phoneChangeAction"
          >
            <span class="lead float-right" v-if="item.suffix">{{
              item.suffix
            }}</span>
          </b-form-input>
        </b-input-group>

        <b-form-invalid-feedback :id="String(item.id)">{{
          validationContext.errors[0]
        }}</b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import { ANSWER_SET } from "@/store/form.module/types";
import WizardItemMixin from "@/components/mixins/wizardItem.js";

export default {
  name: "WizardItemPhone",
  mixins: [WizardItemMixin],
  components: {
    CountryFlag,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      country: {
        name: this.$t("countries.germany"),
        code: "DE",
        dialing: "+49",
      },
      countries: [
        { name: this.$t("countries.germany"), code: "DE", dialing: "+49" },
        { name: this.$t("countries.austria"), code: "AT", dialing: "+43" },
        { name: this.$t("countries.france"), code: "FR", dialing: "+33" },
        {
          name: this.$t("countries.great-britain"),
          code: "GB",
          dialing: "+44",
        },
        { name: this.$t("countries.sweden"), code: "SE", dialing: "+46" },
      ],
    };
  },
  computed: {
    answerWizardItem: {
      get() {
        const answer = this.$store.getters.getAnswer(this.item.id);
        if (answer) {
          return this.$store.getters
            .getAnswer(this.item.id)
            .replace(this.country.dialing, "");
        }
        return null;
      },
      set(answerWizardItem) {
        this.$store.dispatch(ANSWER_SET, {
          answer: answerWizardItem
            ? `${this.country.dialing}${answerWizardItem}`
            : null,
          id: this.item.id,
          errors: [],
          jobFieldPopulated: this.item.populatedJobField,
        });
      },
    },
    itemTitle() {
      return this.item.title;
    },
    isVisible() {
      return this.doesItemDependsOnSomething();
    },
    rules() {
      return {
        required: this.item.required,
        regex: /^[0-9][0-9 +-/()]{7,}$/,
      };
    },
    validationProvider() {
      return this.$refs[`vp-${this.item.id}`];
    },
  },
  methods: {
    selectCountry(country) {
      this.country = country;
      this.answerWizardItem = "";
    },
    async phoneChangeAction() {
      this.changeAction();
      const result = await this.$refs.vp.validate();
      if (result.errors) {
        this.item.feErrors = result.errors;
      }
    },
    async phoneBlurAction() {
      this.blurAction();
      const result = await this.$refs.vp.validate();
      if (result.errors) {
        this.item.feErrors = result.errors;
      }
    },
  },
  created() {
    const dialingCountry = this.countries
      .map((c) => c.dialing)
      .find(
        (dialing) =>
          this.answerWizardItem && this.answerWizardItem.startsWith(dialing)
      );
    if (dialingCountry) {
      this.country =
        this.countries.find((c) => c.dialing === dialingCountry) ||
        this.countries[0];
      if (this.answerWizardItem === this.item.defaultAnswer) {
        this.answerWizardItem = "";
      }
    }
  },
  mounted() {
    this.$refs.vp.validate();
    this.$watch(
      () => {
        return this.$refs.vp.errors;
      },
      (val) => {
        this.item.feErrors = val || [];
      }
    );
  },
};
</script>

<style lang="scss" scoped>
.input-group-prepend {
  .dropdown.b-dropdown.btn-group {
    height: 38px;
  }
  button {
    &:after {
      content: "";
      display: block;
      padding-top: 2px;
    }
    .normal-flag {
      margin-top: -8px;
    }
  }
}

.dropdown-item {
  div {
    span.flag.normal-flag {
      margin: -13px -15px;
    }
  }
}

.dialing-country__selected__prefix {
  position: relative;
  top: -4.5px;
  left: 4.5px;
  padding-right: 4.5px;
}
</style>
