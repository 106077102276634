/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "./state";
import store from "../../store";

export default function useFormMapping(props) {
  const formState = useFormState(props);

  function addService(obj) {
    const allowedKeys = [
      "quantity",
      "service",
      "value_entry",
      "project_sequence",
      "product_id",
      "requiredBy",
    ];
    const item = Object.keys(obj)
      .filter((key) => allowedKeys.includes(key))
      .reduce((x, key) => {
        x[key] = obj[key]; // eslint-disable-line
        return x;
      }, {});

    if (Array.isArray(formState.orderIntake.value.order.services)) {
      formState.orderIntake.value.order.services.push(item);
    } else if (
      Array.isArray(formState.orderIntake.value.order.inputs.services)
    ) {
      formState.orderIntake.value.order.inputs.services.push(item);
    }
  }

  function addProduct(obj) {
    const allowedKeys = ["product", "project_sequence", "quantity", "supplier"];
    const item = Object.keys(obj)
      .filter((key) => allowedKeys.includes(key))
      .reduce((x, key) => {
        x[key] = obj[key]; // eslint-disable-line
        return x;
      }, {});

    if (Array.isArray(formState.orderIntake.value.order.products)) {
      formState.orderIntake.value.order.products.push(item);
    } else if (
      Array.isArray(formState.orderIntake.value.order.inputs.products)
    ) {
      formState.orderIntake.value.order.inputs.products.push(item);
    }
  }

  // TODO: move this somewhere else it does not make sense here...
  function addProfessionalComment(professionalComment) {
    formState.orderIntake.value.order.professional_comment =
      professionalComment;
  }

  return {
    addService,
    addProduct,
    addProfessionalComment,
  };
}
