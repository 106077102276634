/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "./state";
import useFormActions from "./actions";
import useFormCreationActions from "./creation/actions";
import useFormModificationActions from "./modification/actions";
import store from "../../store";
import { FormStrategyEnum } from "@gid/models";

export default function useForm(props, context) {
  const formState = useFormState(props);

  const { previousFlowStep, nextFlowStep } = useFormActions(props, context);

  const creationActions = useFormCreationActions(props, context);
  const modificationActions = useFormModificationActions(props, context);

  // computed properties
  const formSettings = computed(() => {
    return store.getters.formSettings;
  });

  const orderStrategyMode = computed(() => {
    return store.getters.formSettings.orderStrategy.mode;
  });

  const flows = computed(() => {
    return store.getters.flows;
  });

  const currentFlow = computed(() => {
    return store.getters.flows.find((x) => x.step === formState.flowStep.value);
  });

  const allowedToSubmit = computed(() => {
    const conditions = [store.getters.ifRequiredWizardItemsAnswered];

    if (orderStrategyMode.value === FormStrategyEnum.MODIFICATION) {
      const answerQuestionsItem = store.getters.wizardItemByName(
        "wollen-sie-die-fragen-hier-beantworten"
      );
      if (answerQuestionsItem) {
        const filteredItems = store.getters.wizardItemsNotAnswered.filter(
          (wizardItem) => {
            if (
              answerQuestionsItem.answer !== "Ja" &&
              wizardItem.visible &&
              wizardItem.required
            ) {
              return false;
            }
            return wizardItem.visible && wizardItem.required;
          }
        );

        if (Array.isArray(filteredItems) && filteredItems.length === 0) {
          conditions.push(true);
        }
      }
    }
    return conditions.some((x) => x);
  });

  const authAccessToken = computed(() => {
    return store.getters.accessToken;
  });

  // methods
  async function submit() {
    if (orderStrategyMode.value === FormStrategyEnum.CREATION) {
      await creationActions.submit();
    } else if (orderStrategyMode.value === FormStrategyEnum.MODIFICATION) {
      await modificationActions.submit();
    }
  }

  return {
    // computed properties
    formSettings,
    orderStrategyMode,
    flows,
    currentFlow,
    allowedToSubmit,
    authAccessToken,
    // methods
    submit,
    previousFlowStep,
    nextFlowStep,
    preSaveFlowStep: modificationActions.preSaveFlowStep || null,
  };
}
