import { CountryCodeEnum } from "@gid/models";

export function countryToCountryCode(value) {
  if (!value) {
    return null;
  }
  if (value.toLowerCase() === "deutschland") {
    return CountryCodeEnum.GERMANY;
  } else if (value.toLowerCase() === "österreich") {
    return CountryCodeEnum.AUSTRIA;
  } else if (Object.keys(CountryCodeEnum).includes(value.toUpperCase())) {
    return CountryCodeEnum[value.toUpperCase()];
  }
  return CountryCodeEnum.GERMANY;
}

export function formatCurrency(value, locale) {
  return value
    ? Intl.NumberFormat(locale || "en", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value)
    : 0;
}

export function buildContextForFormula(params) {
  const answer = new Proxy(params.items, {
    get(target, prop) {
      const item = target?.[prop];
      let matchOne = () => false;
      let matchAll = () => false;
      let value = item?.value;
      if (!item) {
        return { matchOne, matchAll, value };
      }
      if (!Array.isArray(value)) {
        value = value === null ? null : value || "";
        matchOne = (...args) => {
          return args?.includes(value);
        };
      } else {
        matchOne = (...args) => {
          return args.some((v) => value?.includes(v));
        };
        matchAll = (...args) => {
          return args.every((v) => value?.includes(v));
        };
      }
      return { ...item, matchOne, matchAll, value };
    },
  });

  const opportunity = params.opportunity;
  const service = params.services;
  const product = params.products;
  const completeOrder = params.completeOrder;

  return {
    context: params,
    answer,
    opportunity,
    service,
    product,
    completeOrder,
  };
}

export function getEnv(name) {
  return window?.env?.[name] ?? process?.env?.[name];
}
