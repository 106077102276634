/* eslint-disable no-unused-vars */
import { reactive, toRefs } from "@vue/composition-api";

let form = {
  id: null,
  started: true,
  finished: false,
  name: null,
  settings: {
    progress: true,
    price: true,
    locale: null,
    mapping: null,
  },
  opportunity: {},
  fetched: {
    items: [],
    general: false,
    loading: false,
  },
  api: {},
};

let order = {};

let moduleState = reactive({
  order,
  form,
});

export default function useModuleState(props) {
  // data
  const encodedForm = encodeURI(props.formRef);
  const encodedOpportunityId = encodeURI(props.opportunityId);

  const id = props.formRef;

  const api = {
    createOrder: {
      url: `/api/gid/create`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    modifyOrder: {
      url: `/api/gid/modify`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    preSaveOrder: {
      url: `/api/gid/pre-save`,
      method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
    mapping: {
      url: `/api/gid/mapping`,
      headers: {
        "Content-Type": "application/json",
      },
    },
    pricing: {
      url: `/api/gid/pricing`,
      headers: {
        "Content-Type": "application/json",
      },
    },
    probe: {
      url: `/api/gid/probe/:jobApiId`,
      headers: {
        "Content-Type": "application/json",
      },
      retry: {
        max: 10,
        current: 0,
      },
    },
    retrieveForm: {
      url: `/api/form/${encodedForm}/opportunity/${encodedOpportunityId}/locale/${props.locale}`,
      headers: {
        "Content-Type": "application/json",
      },
    },
  };

  moduleState.form.id = id;
  moduleState.form.api = api;

  return { ...toRefs(moduleState) };
}
