/* eslint-disable no-unused-vars */
import axios from "axios";
import { computed } from "@vue/composition-api";
import { toRefs } from "@vue/composition-api";
import useFormState from "./state";
import store from "../../store";
import i18n from "../../i18n";
import { formatCurrency } from "../../utils";

export default function useSummary(props) {
  const formState = useFormState(props);

  // computed properties
  const hasSummary = computed(() => {
    return store.getters.formSettings.summary;
  });

  // methods
  async function toSummary() {
    formState.buttons.value.summary.loading = true;
    formState.summary.value = true;
    window.scrollTo(0, 0);
    window.top.postMessage(
      {
        action: "scrollIntoView",
        value: { behavior: "smooth", block: "start" },
      },
      "*"
    );
    window.top.postMessage({ action: "summary", value: true }, "*");
    formState.buttons.value.summary.loading = false;

    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);

    // default label
    formState.buttons.value.submit.label = i18n.t("form.buttons.submit.name");

    if (store.getters.order && store.getters.order.isPrecheck) {
      formState.buttons.value.submit.label = i18n.t(
        "form.buttons.submit.partner-precheck.name"
      );
    } else if (
      ["cfa6139f-cdc9-4f3b-ad94-b23f274e69d1"].includes(params.get("form"))
    ) {
      await fetchSummaryData();
    }
    formState.buttons.value.summary.loading = false;
  }

  function backFromSummary() {
    formState.buttons.value.summary.loading = true;
    formState.summary.value = false;
    window.scrollTo(0, 0);
    window.top.postMessage(
      {
        action: "scrollIntoView",
        value: { behavior: "smooth", block: "start" },
      },
      "*"
    );
    window.top.postMessage({ action: "summary", value: false }, "*");
    formState.buttons.value.summary.loading = false;
  }

  async function fetchSummaryData() {
    formState.buttons.value.summary.loading = true;
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);

    const api = {
      summary: {
        url: `/api/gid/summary`,
        headers: {
          "Content-Type": "application/json",
        },
      },
    };
    const items = store.getters.allWizardItemsByOpportunitySfid
      .filter((x) => !["file", "signature"].includes(x.type))
      .map((x) => {
        return { key: x.name, value: x.answer };
      });

    const body = {
      formRef: params.get("form"),
      opportunityId: store.getters.opportunity.sfid,
      items: items,
    };
    try {
      const { data } = await axios.post(api.summary.url, body, {
        headers: api.summary.headers,
      });
      const physicalSiteAccess = i18n.t("form.site-access.physical");
      const remoteSiteAccess = i18n.t("form.site-access.remote");

      const siteAccess = data.flags.is_remote
        ? remoteSiteAccess
        : physicalSiteAccess;

      let submitTranslationKeySuffix = "name";
      if (siteAccess === physicalSiteAccess && data.pricing > 0) {
        submitTranslationKeySuffix = "physical-precheck-with-costs.name";
      } else if (siteAccess === remoteSiteAccess && data.pricing > 0) {
        submitTranslationKeySuffix = "remote-precheck-with-costs.name";
      } else if (siteAccess === physicalSiteAccess && data.pricing <= 0) {
        submitTranslationKeySuffix = "physical-precheck-without-costs.name";
      } else if (siteAccess === remoteSiteAccess && data.pricing <= 0) {
        submitTranslationKeySuffix = "remote-precheck-without-costs.name";
      }

      const submitTranslationKey = `form.buttons.submit.${submitTranslationKeySuffix}`;

      const pricing = formatCurrency(data.pricing, props.locale);
      formState.buttons.value.submit.label = i18n.t(submitTranslationKey, {
        siteAccess,
        pricing,
      });
    } catch (err) {
      console.error(err);
    } finally {
      formState.buttons.value.summary.loading = false;
    }
  }

  return {
    // computed properties
    hasSummary,
    // methods
    backFromSummary,
    toSummary,
  };
}
