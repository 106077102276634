var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isVisible),expression:"isVisible"}]},[_c('b-form-group',{staticClass:"input-group",attrs:{"label-size":"lg"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.itemTitle)}}),(_vm.item.required)?_c('sup',{staticClass:"gid-asterisk"},[_vm._v("*")]):_vm._e()]},proxy:true}])},[(_vm.item.errors.length)?_c('p',{staticClass:"lead"},_vm._l((_vm.item.errors),function(error){return _c('span',{key:error,staticClass:"gid-error"},[_vm._v(_vm._s(_vm.$t(("errors." + (_vm.item.name) + "." + error)))+" ")])}),0):_vm._e(),(_vm.item.explanation)?_c('p',{staticClass:"lead__explanation",domProps:{"innerHTML":_vm._s(_vm.item.explanation)}}):_vm._e(),_c('b-form-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.timeInterval.external.labels[
              this.$store.getters.formSettings.locale
            ].start,"label-size":"lg"}},[_c('b-form-timepicker',_vm._b({attrs:{"locale":this.$store.getters.formSettings.locale},on:{"input":_vm.onTimeIntervalSet,"context":_vm.onTimeIntervalContext},model:{value:(_vm.timeInterval.start),callback:function ($$v) {_vm.$set(_vm.timeInterval, "start", $$v)},expression:"timeInterval.start"}},'b-form-timepicker',
              _vm.timeInterval.internal.labels[
                this.$store.getters.formSettings.locale
              ] || {}
            ,false))],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.timeInterval.external.labels[
              this.$store.getters.formSettings.locale
            ].end,"label-size":"lg"}},[_c('b-form-timepicker',_vm._b({attrs:{"locale":this.$store.getters.formSettings.locale},on:{"input":_vm.onTimeIntervalSet,"context":_vm.onTimeIntervalContext},model:{value:(_vm.timeInterval.end),callback:function ($$v) {_vm.$set(_vm.timeInterval, "end", $$v)},expression:"timeInterval.end"}},'b-form-timepicker',
              _vm.timeInterval.internal.labels[
                this.$store.getters.formSettings.locale
              ] || {}
            ,false))],1)],1),_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"type":"text"},model:{value:(_vm.answerWizardItem),callback:function ($$v) {_vm.answerWizardItem=$$v},expression:"answerWizardItem"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }