<template>
  <div class="form-container">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <div v-for="x in flows" :key="x.id">
          <wizard-flow
            v-if="flowStep == x.step && !summary"
            @nextFlowStep="nextFlowStep()"
            @previousFlowStep="previousFlowStep()"
            @preSaveFlowStep="preSaveFlowStep"
            @toSummary="toSummary()"
            :processPricing="processPricing"
            :title="title"
            :flowStep="flowStep"
            :buttons="buttons"
          >
            <wizard-price
              slot="price"
              v-if="formSettings.price"
              :pricing="pricing"
            ></wizard-price>
            <template
              slot="buttons"
              v-if="!hasSummary && $store.getters.isLastFlow(x.step)"
            >
              <b-button
                variant="primary"
                class="mr-2"
                :disabled="buttons.submit.loading || !allowedToSubmit"
                @click="submit"
              >
                <b-spinner
                  v-if="buttons.submit.loading"
                  class="gid-spinner--button mr-2"
                ></b-spinner>

                <template
                  v-if="$store.getters.order && $store.getters.order.isPrecheck"
                >
                  {{ $t("form.buttons.submit.partner-precheck.name") }}
                </template>
                <template
                  v-else-if="
                    $store.getters.opportunity.sfid === '0060800000uC6hVAAS'
                  "
                >
                  Kostenvoranschlag anfragen
                </template>
                <template v-else>
                  {{ $t("form.buttons.submit.name") }}
                </template>
              </b-button>
            </template>
          </wizard-flow>
        </div>
        <div>
          <div v-if="formSettings.progress && !allowedToSubmit">
            <b-progress :value="progressPercent" class="mb-3"></b-progress>
          </div>
          <wizard-summary v-if="hasSummary && summary">
            <b-row class="my-3" v-if="allowedToSubmit">
              <b-col class="text-center buttons-container">
                <b-button-group>
                  <b-button
                    variant="outline-secondary"
                    class="previous"
                    :disabled="buttons.submit.loading"
                    @click="backFromSummary"
                  >
                    {{ $t("form.buttons.previous.name") }}
                  </b-button>
                  <b-button
                    variant="primary"
                    class="submit"
                    :disabled="buttons.submit.loading"
                    @click="handleSubmit(submit)"
                  >
                    <b-spinner
                      v-if="buttons.submit.loading"
                      class="gid-spinner--button mr-2"
                    ></b-spinner>
                    <template>
                      {{ buttons.submit.label }}
                    </template>
                  </b-button>
                </b-button-group>

                <div
                  v-if="
                    $store.getters.order &&
                    $store.getters.order.isPrecheck &&
                    buttons.submit.loading
                  "
                  class="my-3"
                >
                  <p offset-sm="4">
                    {{ $t("job-modification.submit.loading") }}
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row v-if="$t('form.buttons.summary.explanation')">
              <b-col offset-sm="4">
                <p>
                  {{ $t("form.buttons.summary.explanation") }}
                </p>
              </b-col>
            </b-row>
          </wizard-summary>
          <div v-if="flowStep == 0 && dataPrivacyDefined" class="mt-4">
            {{ $t("data-privacy.info") }}
            <a
              href="https://www.getitdone.rocks/datenschutz"
              target="_blank"
              style="text-decoration: underline"
            >
              {{ $t("data-privacy.link-text") }}</a
            >
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";
import WizardPrice from "@/components/WizardPrice.vue";
import WizardFlow from "@/components/WizardFlow.vue";
import WizardSummary from "@/components/WizardSummary.vue";

import { toRefs, watch, inject, onMounted } from "@vue/composition-api";

export default {
  components: {
    WizardFlow,
    WizardSummary,
    WizardPrice,
  },
  props: {
    title: String,
    form: {
      type: Object,
      required: true,
    },
    processMapping: {
      type: Function,
      required: true,
    },
    processPricing: {
      type: Function,
      required: false,
      default: null,
    },
  },
  setup(props, context) {
    const store = inject("vuex-store");

    // https://github.com/vuejs/composition-api/issues/329
    const useFormState = require("../composables/form/state").default; // eslint-disable-line
    const useFormPricing = require("../composables/form/pricing").default; // eslint-disable-line
    const useProgress = require("../composables/form/progress").default; // eslint-disable-line
    const useSummary = require("../composables/form/summary").default; // eslint-disable-line
    const useForm = require("../composables/form").default; // eslint-disable-line

    const formState = useFormState(props);

    const { pricing } = useFormPricing({
      ...props,
      locale: store.getters.formSettings.locale,
    });

    const { progressPercent } = useProgress(props);

    const { hasSummary, backFromSummary, toSummary } = useSummary({
      ...props,
      locale: store.getters.formSettings.locale,
    });

    const {
      formSettings,
      orderStrategyMode,
      flows,
      currentFlow,
      allowedToSubmit,
      authAccessToken,
      submit,
      previousFlowStep,
      nextFlowStep,
      preSaveFlowStep,
    } = useForm(props, context);

    if (flows.value) {
      formState.flowStep.value = flows.value[0].step;
    }

    watch(
      () => store.getters.earliestFlowWithErrors,
      (flow) => {
        if (flow && Number(flow.step) >= 0) {
          formState.flowStep.value = flow.step;
        }
      }
    );

    onMounted(() => {
      if (formSettings.value.price) {
        props.processPricing();
      }
    });

    return {
      // data
      ...formState,
      // computed properties
      pricing,
      progressPercent,
      hasSummary,
      formSettings,
      orderStrategyMode,
      flows,
      currentFlow,
      allowedToSubmit,
      authAccessToken,
      // methods
      backFromSummary,
      toSummary,
      submit,
      previousFlowStep,
      nextFlowStep,
      preSaveFlowStep,
    };
  },
  computed: {
    dataPrivacyDefined() {
      const value = this.$i18n.t("data-privacy.info");
      return value !== "" && value !== "data-privacy.info";
    },
  },
};
</script>
