/* eslint-disable no-unused-vars */
import { computed, reactive } from "@vue/composition-api";
import useFormState from "./state";
import store from "../../store";

export default function useFormMetadata(props, context) {
  const formState = useFormState(props);

  const currentFlow = computed(() => {
    return store.getters.flows.find((x) => x.step === formState.flowStep.value);
  });

  function processMetadataPayload() {
    const metadata = {
      form: {
        id: props.form.id,
        opportunityId: props.form.opportunity.sfid,
        flowId: currentFlow.value.id,
      },
    };
    const file = new File([JSON.stringify(metadata)], "metadata", {
      type: "application/json",
    });
    formState.orderIntake.value.formData.append("metadata", file);
  }

  function processFilesMetadataPayload() {
    const allowedFileNames = Array.from(
      formState.orderIntake.value.formData.keys()
    ).filter((x) => !["order", "answers", "metadata"].includes(x));
    const file = new File(
      [JSON.stringify({ allowedFileNames })],
      "files-metadata",
      { type: "application/json" }
    );
    formState.orderIntake.value.formData.append("files-metadata", file);
  }

  return {
    processMetadataPayload,
    processFilesMetadataPayload,
  };
}
