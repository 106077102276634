/* eslint-disable no-unused-vars */
import { computed } from "@vue/composition-api";
import useFormState from "../state";
import store from "../../../store";

export default function useFormOpportunity(props) {
  const formState = useFormState(props);

  function processOpportunity() {
    formState.orderIntake.value.order.opportunity =
      store.getters.opportunity.sfid;
  }

  return {
    processOpportunity,
  };
}
