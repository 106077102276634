var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-container"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._l((_vm.flows),function(x){return _c('div',{key:x.id},[(_vm.flowStep == x.step && !_vm.summary)?_c('wizard-flow',{attrs:{"processPricing":_vm.processPricing,"title":_vm.title,"flowStep":_vm.flowStep,"buttons":_vm.buttons},on:{"nextFlowStep":function($event){return _vm.nextFlowStep()},"previousFlowStep":function($event){return _vm.previousFlowStep()},"preSaveFlowStep":_vm.preSaveFlowStep,"toSummary":function($event){return _vm.toSummary()}}},[(_vm.formSettings.price)?_c('wizard-price',{attrs:{"slot":"price","pricing":_vm.pricing},slot:"price"}):_vm._e(),(!_vm.hasSummary && _vm.$store.getters.isLastFlow(x.step))?_c('template',{slot:"buttons"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","disabled":_vm.buttons.submit.loading || !_vm.allowedToSubmit},on:{"click":_vm.submit}},[(_vm.buttons.submit.loading)?_c('b-spinner',{staticClass:"gid-spinner--button mr-2"}):_vm._e(),(_vm.$store.getters.order && _vm.$store.getters.order.isPrecheck)?[_vm._v(" "+_vm._s(_vm.$t("form.buttons.submit.partner-precheck.name"))+" ")]:(
                  _vm.$store.getters.opportunity.sfid === '0060800000uC6hVAAS'
                )?[_vm._v(" Kostenvoranschlag anfragen ")]:[_vm._v(" "+_vm._s(_vm.$t("form.buttons.submit.name"))+" ")]],2)],1):_vm._e()],2):_vm._e()],1)}),_c('div',[(_vm.formSettings.progress && !_vm.allowedToSubmit)?_c('div',[_c('b-progress',{staticClass:"mb-3",attrs:{"value":_vm.progressPercent}})],1):_vm._e(),(_vm.hasSummary && _vm.summary)?_c('wizard-summary',[(_vm.allowedToSubmit)?_c('b-row',{staticClass:"my-3"},[_c('b-col',{staticClass:"text-center buttons-container"},[_c('b-button-group',[_c('b-button',{staticClass:"previous",attrs:{"variant":"outline-secondary","disabled":_vm.buttons.submit.loading},on:{"click":_vm.backFromSummary}},[_vm._v(" "+_vm._s(_vm.$t("form.buttons.previous.name"))+" ")]),_c('b-button',{staticClass:"submit",attrs:{"variant":"primary","disabled":_vm.buttons.submit.loading},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[(_vm.buttons.submit.loading)?_c('b-spinner',{staticClass:"gid-spinner--button mr-2"}):_vm._e(),[_vm._v(" "+_vm._s(_vm.buttons.submit.label)+" ")]],2)],1),(
                  _vm.$store.getters.order &&
                  _vm.$store.getters.order.isPrecheck &&
                  _vm.buttons.submit.loading
                )?_c('div',{staticClass:"my-3"},[_c('p',{attrs:{"offset-sm":"4"}},[_vm._v(" "+_vm._s(_vm.$t("job-modification.submit.loading"))+" ")])]):_vm._e()],1)],1):_vm._e(),(_vm.$t('form.buttons.summary.explanation'))?_c('b-row',[_c('b-col',{attrs:{"offset-sm":"4"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("form.buttons.summary.explanation"))+" ")])])],1):_vm._e()],1):_vm._e(),(_vm.flowStep == 0 && _vm.dataPrivacyDefined)?_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("data-privacy.info"))+" "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"https://www.getitdone.rocks/datenschutz","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("data-privacy.link-text")))])]):_vm._e()],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }